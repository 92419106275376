import React, {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import pimindServices from 'src/app/services/pimind-services'
import {summarySelector} from 'src/app/slices/summary/summary.slice'
import PieChart from '../../shared/charts/pie-chart/pie-chart.component'
import {DropDown} from '../../shared/dropdown/drop-down.component'
import './asset-class-weight-by-month-component.scss'
import * as uuid from 'uuid'
import {Loader} from '../../shared/loader/loader.component'
import _pdfExportService from 'src/app/services/pdfExport-service'
import html2canvas from 'html2canvas'
import {TabsDisclosureComponent} from '../tab-disclosure/tabs-disclosure.component'
import { indexesHidesBackTestData } from '../../shared/const-value'

type AssetClassWeightByMonth = {
  showBackTestData: boolean
}
export const AssetClassWeightByMonthComponent: React.FunctionComponent<AssetClassWeightByMonth> = ({showBackTestData}) => {
  const {data} = useSelector(summarySelector)
  const yearList: Array<string> = []
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const [chartSeriesName, setSeriesName] = useState('')
  let formattedData: Array<object> = []
  const years = getYears()
  const hidePieChart = ["balanced","tbier","gem","pimusfb", "pimbap" ]
  
  const lastday = function (year: number, month: number) {
    const lastDateMonth = new Date(year, month + 1, 0).getDate()
    setSelectedMonthLastDate(lastDateMonth)
  }
  const formattedAsOfDate=new Date(data?.asOfDate?.asOfDate)

  function getYears() {
    for (
      let i = new Date(data?.data?.launchDate).getFullYear();
      i <= new Date(data?.asOfDate?.asOfDate).getFullYear();
      i++
    ) {
      yearList.push(i.toString())
    }
    return yearList
  }

  const [selectedYear, setSelectedYear] = useState(
    formattedAsOfDate.getMonth() > 0?formattedAsOfDate.getFullYear():formattedAsOfDate.getFullYear()-1,
  )
  const [selectedMonth, setSelectedMonth] = useState(
    months[formattedAsOfDate.getMonth() > 0?formattedAsOfDate.getMonth()-1:11],
  )
  const [SelectedMonthLastDate, setSelectedMonthLastDate] = useState(
    new Date(selectedYear, months.indexOf(selectedMonth) + 1, 0).getDate(),
  )
  const [formattedFinalData, setFormattedFinalData]: any = useState({})
  const [finalDataDate, setFinalDataDate]: any = useState()
  const [isLoading, setIsloading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [isDataAvailable, setIsDataAvailable] = useState(false)
  const [showPieChart, setShowPieChart] = useState(false)

  useEffect(() => {
    lastday(
      new Date(data?.asOfDate?.asOfDate).getFullYear(),
      months.indexOf(months[new Date(data?.asOfDate?.asOfDate).getMonth()]),
    )
    getDataForMonth(selectedMonth, selectedYear)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth, selectedYear])

  useEffect(() => {
    if (data?.asOfDate?.asOfDate !== undefined) {
      setSeriesName(data.data.sourceName)
    }
  }, [data])
  const getPDF = useCallback(async () => {
    const documentElement = document.getElementById('pie-chart')
    if (documentElement !== null) {
      html2canvas(documentElement, {scale: 5}).then(
        async (result: { toDataURL: (id: string) => any }) => {
          const dataURL = result.toDataURL('image/jpeg')
          await _pdfExportService.download_pdf_file(
            dataURL,
            `${finalDataDate}`,
            '12 Month',
            chartSeriesName,
            data.data.sourceName,
            true,
            new Date(finalDataDate).getTime() < new Date(data?.data?.launchDate).getTime(),
            formattedFinalData.length>8?true:false
          )
        },
      )
    }
  }, [data, chartSeriesName, finalDataDate,formattedFinalData])

  const getDataForMonth = (selectedMonth: string, selectedYear: number) => {
    setIsloading(true)
    setIsError(false)
    setIsDataAvailable(false)
    const month =
      months.indexOf(selectedMonth) + 1 < 10
        ? '0' + (months.indexOf(selectedMonth) + 1)
        : months.indexOf(selectedMonth) + 1
    const endDate = `${selectedYear}-${month}-${SelectedMonthLastDate}`
    const startDate = `${selectedYear}-${month}-01`
    if (new Date() > new Date(startDate)) {
      if (indexesHidesBackTestData().includes(data.data.sourceCode) && new Date(endDate)<new Date(data?.data?.launchDate)) {
        setIsloading(false)
        setIsError(true)
        setIsDataAvailable(false)
      }
      else {
        pimindServices.getLatestDate(startDate, endDate).then(
          (res: any) => {
            if (res.data) {
              const lastAvailbaleDataDate = res?.data?.asOfDate.split('T')[0]
              const finalMonth = lastAvailbaleDataDate.split('-')[1]
              const finalDay = lastAvailbaleDataDate.split('-')[2]
              const finalDataDate = `${finalMonth}/${finalDay}/${selectedYear}`
              setFinalDataDate(finalDataDate)
              pimindServices
                .getWeightsData(
                  'By Month',
                  lastAvailbaleDataDate,
                  lastAvailbaleDataDate,
                  false,
                )
                .then(
                  res => {
                    if (res?.data) {
                      setIsDataAvailable(true)
                      const dataSet = Object.entries(res.data)
                      dataSet.forEach((res: Array<any>) => {
                        let obj = {
                          y: (res[1].historicalData * 100) / 100,
                          name: res[0],
                        }
                        formattedData.push(obj)
                      })
                      setFormattedFinalData(formattedData)
                      checkToShowPieChart(formattedData)
                      setIsloading(false)
                    }
                  },
                  () => {
                    setIsloading(false)
                    setIsError(true)
                  },
                )      
            }
            else {
              setIsloading(false)
              setIsError(true)
              setIsDataAvailable(false)
            }
          },
          () => {
            setIsloading(false)
            setIsError(true)
          },
        )
      }
    } else {
      setIsloading(false)
      setIsError(true)
      setIsDataAvailable(false)
    }
  }

  function checkToShowPieChart(data: object[]) {
    let sum = 0
    let isNegativeValueCheck: boolean =false;
    data.forEach((res: any) => {
      if(res.y < 0){
        isNegativeValueCheck = true
      }
      sum += res.y
    })
    if (sum === 100 && !isNegativeValueCheck) {
      let loc = window.location
        const index=loc.pathname.split('/')[1]
        const validIndex = hidePieChart.filter((el) => el === index )
        if (validIndex.length !== 0) {
        setShowPieChart(false)
        } else {
          setShowPieChart(true)
      }
    } else {
      setShowPieChart(false)
    }
    setIsloading(false)
  }

  return (
    <>
    <div className="main-conatainer">
      <>
        <div className="btn-container">
          <h2 className="subHeading">
            Weights (By month)
            {!isError ? (
              <span className="date-header">(As of {finalDataDate})</span>
            ) : (
              ''
            )}
          </h2>
          <div className="select-container">
            <div className="select-box">
              <DropDown
                dropDownList={months}
                updateSel={(event: any) => {
                  setSelectedMonth(event)
                  lastday(selectedYear, months.indexOf(event))
                }}
                selectedItem={'' + selectedMonth}
              />
            </div>
            <div className="select-box">
              <DropDown
                dropDownList={years}
                updateSel={(event: number) => {
                  setSelectedYear(event)
                  lastday(event, months.indexOf(selectedMonth))
                }}
                selectedItem={'' + selectedYear}
              />
            </div>
            <div className="select-box">
                <button className='btn-tertiary export-btn' onClick={() => {
                  getPDF()
                }}>Export PDF
                </button>
            </div>
          </div>
        </div>
          {!isLoading && !isError && (
            <div className={showPieChart ? 'pie-chart-cntr' :' pie-chart-cntr align'}>
          <div className="chart-container" id="pie-chart">
            {showPieChart && (
              <div >
                <PieChart data={formattedFinalData} />
              </div>
            )}
            <div className="chart-data">
              <div className="outer-card">
                <div className="detail-content">
                  <div className="title">
                    <h2>Asset Class Weights & Composition</h2>
                  </div>
                  <div className="table">
                    <table className="pim-table">
                      <thead>
                        <tr>
                          {
                            <>
                              <th>Asset Class Name</th>
                              <th>Composition</th>
                            </>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {formattedFinalData?.map((res: any, index: number) => (
                          <tr role="row" key={uuid.v4()}>
                            <td>
                              {showPieChart ? (
                                <span className={`color-box-${index}`}>
                                  {res.name}
                                </span>
                              ) : (<>
                                  {res.name}
                                </>
                              )}
                            </td>
                            <td>{res?.y}%</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
              </div>
              </div>
        )}
      </>
      {isLoading && !isError && !isDataAvailable && (
        <div className="loader-card">
            <Loader/>
        </div>
      )}
      {isError && !isDataAvailable && (
        <div className="data-not-found">
          <span>Data unavailable</span>
        </div>
      )}
    </div>
     <div className="horizontal-row"/>
      {data?.data?.launchDate &&
        <TabsDisclosureComponent
          showBackTestDisclosure={showBackTestData || new Date(finalDataDate).getTime() < new Date(data.data.launchDate).getTime()}/>}
    </>
  )
}
