import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import pimindServices from 'src/app/services/pimind-services'
import {summarySelector} from 'src/app/slices/summary/summary.slice'
import './index-performance-component.scss'
import * as uuid from 'uuid'
import {Loader} from '../../shared/loader/loader.component'
import { getId, isDate } from 'src/app/utils/utils'


type indexPerformanceProps = {
  isCustomSelected: boolean,
  customStartDate: any,
  customEndDate:any
}

type TableDataType = {
  asOfDate: string
  dur: number
  isSolactive: true
  mtd: number
  oneDay: number
  oneYr: number
  sourceCode: string
  twoYr: number
  ytd: number
}

export const IndexPerformanceComponent: React.FunctionComponent<
  indexPerformanceProps
  > = ({ isCustomSelected, customStartDate, customEndDate }) => {
  const {data} = useSelector(summarySelector)
  const tableOneHeader = [
    'Index Name',
    '1 Day TR',
    'MTD TR',
    'YTD TR',
    '1 Yr TR',
    'Duration',
  ]
  const tableTwoHeader = [
    'Index Name',
    '1 Day TR',
    'MTD TR',
    'YTD TR',
    '1 Yr TR',
    '2 Yr TR',
  ]
  const [tableData, setTableData] = useState<TableDataType>()
  const [isLoading, setIsloading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [customRangeData,setCustomRangeData] : any=useState(0)

  useEffect(() => {
    const id = getId();
    if (data?.asOfDate?.asOfDate !== undefined) {
      setIsloading(true)
      setIsError(false)
      const date = data?.asOfDate?.asOfDate.split('T')[0].split('-')
      const formatedDate = `${date[0]}-${date[1]}-${date[2]}`
      pimindServices.getIndexPerformanceData(id, formatedDate).then(
        res => {
          if (res?.data) {
            setIsloading(false)
            setIsError(false)
            setTableData(res.data)
          } else {
            setIsloading(false)
            setIsError(true)
          }
        },
        () => {
          setIsloading(false)
          setIsError(true)
        },
      )
    }else {
      setIsloading(false)
      setIsError(true)
    }
  }, [data])
  useEffect(() => {
    if (isCustomSelected && isDate(customStartDate.$d) && isDate(customEndDate.$d) ) {
      const id = getId()
      const startDate = customStartDate.$d.toLocaleDateString().split('/')
      const endDate=customEndDate.$d.toLocaleDateString().split('/')
      pimindServices.getCustomRangePerformanceData(id, `${startDate[2]}-${
              startDate[0] < 10 ? '0' + startDate[0] : startDate[0]
            }-${startDate[1] < 10 ? '0' + startDate[1] : startDate[1]}`,
            `${endDate[2]}-${endDate[0] < 10 ? '0' + endDate[0] : endDate[0]}-${
              endDate[1] < 10 ? '0' + endDate[1] : endDate[1]
            }`,).then((res) => {
              console.log(res);
        if (res?.data) {
          setCustomRangeData(res.data)
          setIsloading(false)
          setIsError(false)
        }else{
          setCustomRangeData(null)
          setIsloading(false)
          setIsError(false)
        }
      },() => {
        setIsloading(false)
        setIsError(true)
      })
    }
  },[isCustomSelected,customStartDate,customEndDate])
  return (
    <div className="historical-main-card">
      <div className="main-card">
        <div className="detail-content">
          <div className="table-title">
            <h2>Index Performance</h2>
          </div>
          {!isLoading && !isError && tableData && (
            <div className="table">
              <table className="pim-table">
                <thead>
                  <tr>
                    {tableData?.isSolactive
                      ? tableTwoHeader.map((res: string) => {
                          return <th key={uuid.v4()}>{res}</th>
                        })
                      : tableOneHeader.map((res: string) => {
                          return <th key={uuid.v4()}>{res}</th>
                        })}
                    {isCustomSelected && (
                      <th key={uuid.v4()}>
                        From{' '}
                        {customStartDate.$d.toLocaleDateString(undefined, {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        })}{' '}
                        To{' '}
                        {customEndDate.$d.toLocaleDateString(undefined, {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        })}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {
                    <tr role="row">
                      <td>{data.data.sourceName}</td>
                      <td>
                        {tableData.oneDay !== null
                          ? tableData.oneDay.toFixed(2) + '%'
                          : '-'}
                      </td>
                      <td>
                        {tableData.mtd !== null
                          ? tableData.mtd.toFixed(2) + '%'
                          : '-'}
                      </td>
                      <td>
                        {tableData.ytd !== null
                          ? tableData.ytd.toFixed(2) + '%'
                          : '-'}
                      </td>
                      <td>
                        {tableData.oneYr !== null
                          ? tableData.oneYr.toFixed(2) + '%'
                          : '-'}
                      </td>
                      <td>
                        {tableData?.isSolactive
                          ? `${
                              tableData.twoYr !== null
                                ? tableData.twoYr.toFixed(2) + '%'
                                : '-'
                            }`
                          : `${
                              tableData.dur !== null
                                ? tableData.dur.toFixed(2)
                                : '-'
                            }`}
                      </td>
                      {isCustomSelected && (
                        <td>
                          {customRangeData !== null
                            ? customRangeData.toFixed(2) + '%'
                            : '-'}
                        </td>
                      )}
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          )}
          {isLoading && !isError && <Loader />}
          {isError && !isLoading && (
            <div className="error-text-section">
              <span>Data Unavailable</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
