import * as XLSX from 'xlsx-js-style';
class excelExportService {

  download_excel_file(FileData: any, headers: string[], name: string, showDisclosure: boolean) {
    const excelData = showDisclosure ? [...FileData, ["", "", ""], ["HYPOTHETICAL PERFORMANCE RESULTS HAVE MANY INHERENT LIMITATIONS, SOME OF WHICH ARE DESCRIBED BELOW. NO REPRESENTATION IS BEING MADE THAT ANY ACCOUNT WILL OR IS LIKELY TO ACHIEVE PROFITS OR LOSSES SIMILAR TO THOSE SHOWN. IN FACT, THERE ARE FREQUENTLY SHARP DIFFERENCES BETWEEN HYPOTHETICAL PERFORMANCE RESULTS AND THE ACTUAL RESULTS SUBSEQUENTLY ACHIEVED BY ANY PARTICULAR TRADING PROGRAM.", "", ""], ["", "", ""], ["ONE OF THE LIMITATIONS OF HYPOTHETICAL PERFORMANCE RESULTS IS THAT THEY ARE GENERALLY PREPARED WITH THE BENEFIT OF HINDSIGHT. IN ADDITION, HYPOTHETICAL TRADING DOES NOT INVOLVE FINANCIAL RISK, AND NO HYPOTHETICAL TRADING RECORD CAN COMPLETELY ACCOUNT FOR THE IMPACT OF FINANCIAL RISK IN ACTUAL TRADING. FOR EXAMPLE, THE ABILITY TO WITHSTAND LOSSES OR TO ADHERE TO A PARTICULAR TRADING PROGRAM IN SPITE OF TRADING LOSSES ARE MATERIAL POINTS WHICH CAN ALSO ADVERSELY AFFECT ACTUAL TRADING RESULTS. THERE ARE NUMEROUS OTHER FACTORS RELATED TO THE MARKETS IN GENERAL OR TO THE IMPLEMENTATION OF ANY SPECIFIC TRADING PROGRAM WHICH CANNOT BE FULLY ACCOUNTED FOR IN THE PREPARATION OF HYPOTHETICAL PERFORMANCE RESULTS AND ALL OF WHICH CAN ADVERSELY AFFECT ACTUAL TRADING RESULTS.", "", ""]] : [...FileData]
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    //setting the header
    const headerRowData = headers
    //add header
    XLSX.utils.sheet_add_aoa(worksheet, [headerRowData], { origin: 0 });
    //adding style to headers
    for (let i = 0; i < headerRowData.length; i++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: i })
      const cell = worksheet[cellAddress]
      cell.s = { font: { bold: true } }
    }
    for (let col = 0; col < excelData.length; col++) {
      worksheet['!cols'] = [{ width: 40 }, { width: 60 }, { width: 40 }]
      if (col >= excelData.length - 4 && showDisclosure) {
        //we are using  +2 because it is starting from 0 and we have header as well
        const column1 = `A${col + 2}`
        const column3 = `C${col + 2}`
        //logic to merge or combine 3 cells into one
        const mergeObject = { s: XLSX.utils.decode_cell(column1), e: XLSX.utils.decode_cell(column3) };
        if (!worksheet['!merges']) {
          worksheet['!merges'] = [];
        }
        worksheet['!merges'].push(mergeObject)
        //logic to set wrap text 
        const range = XLSX.utils.decode_range(`${column1}:${column3}`)
        for (let row = range.s.r; row <= range.e.r; row++) {
          for (let col = range.s.c; col <= range.e.c; col++) {
            const cell = worksheet[XLSX.utils.encode_cell({ r: row, c: col })];
            if (cell) {
              cell.s = { ...cell.s, wrapText: true }
              cell.s.alignment = { wrapText: true }
              cell.z = '@'
            }
          }
        }
        //logic to give height to cells
        if (excelData[col].join('').length > 50 && excelData[col].join('').length > 50) {
          worksheet['!rows'] = worksheet['!rows'] || []
          worksheet['!rows'][col + 1] = { hpx: 45 }
        }
        if (excelData[col].join('').length > 500) {
          worksheet['!rows'] = worksheet['!rows'] || []
          worksheet['!rows'][col + 1] = { hpx: 90 }
        }

      }
    }
    XLSX.utils.book_append_sheet(workbook, worksheet);
    XLSX.writeFile(workbook, `${name}_${formatDate()}.xlsx`)
  }
}
function formatDate() {
  let date = new Date()
  let day = date.getDate()
  let month = date.getMonth() + 1
  return month + '.' + day + '.' + date.getFullYear()
}

const _excelExportService = new excelExportService()

export default _excelExportService