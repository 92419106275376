import './characteristics-card.component.scss'
import React from 'react';
import * as uuid from 'uuid'
import {Loader} from 'src/app/components/shared/loader/loader.component'

type CharacteristicCardProps = {
  catData: any
  isError: boolean
  isLoading: boolean
}

export const CharacteristicCardComponent: React.FunctionComponent<
  CharacteristicCardProps
> = ({catData, isLoading, isError}) => {
  const formatDate = (date: string) => {
    if (date) {
      const dateFormat = date.split('T')[0].split('-')
      return `${dateFormat[1]}/${dateFormat[2]}/${dateFormat[0]}`
    }
  }

  return (
    <div className="characteristics-card">
      <div className="detail-item">
        {!isLoading && !isError && (
          <div key={catData.data?.id || -1}>
            <div className="group-title">
              <h2>
                Characteristics
                <span className="date-header">
                  (As of {formatDate(catData?.asOfDate)})
                </span>
              </h2>
            </div>
            <div className="detail-content">
              <div className="perf-table">
                <table className="pim-table">
                  <thead>
                    <tr>
                      <th>Sector</th>
                      <th>Index Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    {catData.data.map(
                      (data: {
                        asOfDate: string
                        assetClassType: string
                        value: number
                      }) => (
                        <tr role="row" key={uuid.v4()}>
                          <td>{data?.assetClassType}</td>
                          <td>{data?.value}%</td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {isLoading && !isError && (
          <>
            <Loader />
          </>
        )}
        {isError && (
          <>
            <div className="group-title">
              <h2>Characteristics</h2>
            </div>
            <div className="error-text">
              <span>Data Unavailable</span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
