import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from '@reduxjs/toolkit'
import {Card} from 'src/app/models'
import pimindServices from 'src/app/services/pimind-services'
import {RootState} from '..'

export type IndexDataCardState = {
  loading: boolean
  data: Card[] | []
  hasError: boolean
}

export const initialState: IndexDataCardState = {
  loading: true,
  hasError: false,
  data: [],
}

const IndexDataSlice = createSlice({
  name: 'indexData',
  initialState,
  reducers: {
    getIndexDataSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload
      state.loading = false
      state.hasError = false
    },
    startLoading: state => {
      state.loading = true
    },
    getIndexDataFailure: state => {
      state.loading = false
      state.hasError = true
    },
  },
})

export const {startLoading, getIndexDataFailure, getIndexDataSuccess} =
  IndexDataSlice.actions

export const indexSelector = (state: RootState) => state.indexData

const indexReducer = IndexDataSlice.reducer

export default indexReducer

export const fetchIndexData =
  (): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
    dispatch(startLoading)
    pimindServices.getIndexData().then(
      res => {
        localStorage.setItem('indexData', JSON.stringify(res.data))
        dispatch(getIndexDataSuccess(res.data))
      },
      error => {
        dispatch(getIndexDataFailure())
      },
    )
  }
