import {createBrowserRouter, createRoutesFromElements, Outlet, Route} from 'react-router-dom';

import {ErrorPage} from './components/error/error.component';
import {FooterComponent} from './components/footer/footer.component';
import {IndexCardComponent} from './components/home';
import {NavbarComponent} from './components/navbar';
import {IndexDetailComponent} from './components/index-detail';
import { NotFoundPage } from './components/not-found/not-found.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { MaintenancePageComponent } from './components/maintenance-page/maintenance-page.component';
const AppLayout = () => (
  <>
    <ContactUsComponent />
    <NavbarComponent />
    <Outlet />
    <FooterComponent />
  </>
);

const NotFoundPageLayout = () => (
  <>
    <NavbarComponent isError={true} />
    <NotFoundPage />
  </>
)

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
    <Route element={<AppLayout />}>
      <Route
        path=""
        element={<IndexCardComponent />}
        errorElement={<ErrorPage />}
        />
      <Route
        path=":id"
        element={<IndexDetailComponent />}
        errorElement={<ErrorPage />}
        />
        <Route
        path="/pages/data.aspx/"
        element={<IndexDetailComponent />}
        errorElement={<ErrorPage />}
        />
        <Route
        path="maintenance"
        element={<MaintenancePageComponent/>}
        />
    </Route>
    <Route path="*" element={<NotFoundPageLayout />} />
  </>
  )
);
