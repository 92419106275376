export const indexDatamap: any = new Map([
    ["BAL", "balanced"],
    ["EFI", "equityfusion"],
    ["GOI", "globaloptima"],
    ["TBI", "tbi"],
    ["TBIR", "tbier"],
    ["GEM", "gem"],
    ["USFB", "pimusfb"],
    ["PIMBAP", "pimbap"],
    ["SBI", "sbi"],
]);

export function getIndexDataKey(val: string) {
    return [...indexDatamap].find(([key, value]) => val === value)?.[0];
}
export function getIndexDataValue(val: string) {
    val === 'efi' && (val = 'equityfusion');
    val === 'goi' && (val = 'globaloptima');
    val === 'tbir' && (val = 'tbier');
    return [...indexDatamap].find(([key, value]) => value.includes(val))?.[1];
}