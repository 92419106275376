import './summary-card.component.scss'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined'
import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import {useEffect, useState} from 'react'
import { Loader } from 'src/app/components/shared/loader/loader.component'

type SummaryCardProps = {
  summaryData: any,
  loading:boolean
}

export interface SimpleDialogProps {
  open: boolean
  onClose: (value: string) => void
}

function formatDate(dateString: string) {
  let date = new Date(Date.parse(dateString))
  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()
  return `${month}/${day}/${year}`
}

export const SummaryCardComponent: React.FunctionComponent<
  SummaryCardProps
  > = ({summaryData ,loading}: SummaryCardProps) => {
  function modalClickhandler() {
    setOpen(true)
  }

  const [open, setOpen] = useState(false)
  const [isError, setIsError] = useState(false)

    useEffect(() => {
    if (!summaryData?.ticker && !loading  ) {
        setIsError(true)
      }
  },[summaryData,loading])

  const handleClose = (value: string) => {
    setOpen(false)
  }

  function SimpleDialog(props: SimpleDialogProps) {
    const {onClose, open} = props

    const handleClose = () => {
      onClose('')
    }

    return (
      <>
        <Dialog onClose={handleClose} open={open}>
          <div className="dialog-panel">
            <div className="close-button" onClick={handleClose}>
              <CancelOutlinedIcon className="close-icon" />
            </div>
            <iframe
              src={summaryData.videoURL}
              height="100%"
              width="100%"
              title={summaryData.ticker}
              allowFullScreen
            />
          </div>
        </Dialog>
      </>
    )
  }

  return (
    <div className="summary-card">
      <div className="detail-item">
        {!isError && (
          <div key={summaryData.data?.id || -1}>
            <div className="group-title">
              <h2>Summary</h2>
            </div>
            {!loading &&(<div className="detail-content">
              <div className="perf-table">
                <table className="pim-table">
                  <thead>
                    <tr>
                      <th colSpan={2}>General Information</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr role="row">
                      <td className="row-first">Index Launch Date</td>
                      <td className="row-first">
                        {formatDate(summaryData.launchDate)}
                      </td>
                    </tr>
                    <tr>
                      <td>Ticker</td>
                      <td>{summaryData.ticker}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {summaryData.videoURL ? (
                <>
                  <div className="modal-view" onClick={modalClickhandler}>
                    <PlayCircleOutlineOutlinedIcon className="play-icon" />
                  </div>
                  <div className="description">
                    <span className="description-source">
                      {summaryData.sourceName}
                    </span>
                    <span className="description-details">
                      {summaryData.videoSummary}
                    </span>
                  </div>
                </>
              ) : (
                ''
              )}
              <div className="dialog">
                <SimpleDialog open={open} onClose={handleClose} />
              </div>
            </div>)}
          </div>
        )}
        {isError && (
          <>
            <div className="group-title">
              <h2>Summary</h2>
            </div>
            <div className="error-text">
              <span>Data Unavailable</span>
            </div>
          </>
        )}
        {loading && (
          <>
            <Loader />
          </>
        )}
      </div>
    </div>
  )
}
