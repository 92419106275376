import { AxiosResponse } from 'axios'

import { http } from '../http-common'
import { getId } from '../utils/utils'

class PimindServices {

  getIndexData(): Promise<AxiosResponse> {
    const url = `api/summary`
    return http.get(url)
  }

  getAsofDate(): Promise<AxiosResponse> {
    const url = `/api/get/asOfDate`
    return http.get(url)
  }

  getCharacteristicData(
    startDate: string,
    endDate: string,
  ): Promise<AxiosResponse> {
    const id = getId()
    const url = `api/get/characteristics/${id}?startDate=${startDate}&endDate=${endDate}`
    return http.get(url)
  }
  gethistoricalData(
    type: string,
    startDate: string,
    endDate: string,
    isCustom: boolean,
  ): Promise<AxiosResponse> {
    const id = getId()
    const url = `api/historical/${type}/${id}?startDate=${startDate}&endDate=${endDate}&isCustom=${isCustom}`
    return http.get(url)
  }

  getassetClassHistorical(
    classType: string,
    startDate: string,
    endDate: string,
    isCustom: boolean,
  ): Promise<AxiosResponse> {
    const id = getId()
    const url = `api/weights/${classType}/${id}?startDate=${startDate}&endDate=${endDate}&isCustom=${isCustom}`
    return http.get(url)
  }

  getLatestDate(startDate: string, endDate: string) {
    const id = getId()
    const url = `api/get/last/available/date/${id}/${startDate}/${endDate}`
    return http.get(url)
  }

  getIndexPerformanceData(
    id: string,
    asOfDate: string,
  ): Promise<AxiosResponse> {
    const url = `api/get/indexPerformance/${id}?asOfDate=${asOfDate}`
    return http.get(url)
  }

  getWeightsData(
    classType: string,
    startDate: any,
    endDate: any,
    isCustom: boolean,
  ) {
    const id = getId()
    const url = `api/weights/${classType}/${id}?startDate=${startDate}&endDate=${endDate}&isCustom=${isCustom}`
    return http.get(url)
  }

  getDocument(docName: String) {
    const url = `api/document/${docName}`
    return http.get(url, { responseType: 'blob' })
  }

  getCustomRangePerformanceData(id: string, startDate: string, endDate: string) {
    const url = `api/get/custom/indexPerformance/${id}?startDate=${startDate}&endDate=${endDate}`
    return http.get(url)
  }
}

const pimindServices = new PimindServices()

export default pimindServices
