import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {Card} from 'src/app/models'
import {
  fetchIndexData,
  indexSelector,
} from 'src/app/slices/indexData/index.slice'
import {useAppDispatch} from 'src/app/store/store'
import './home.component.scss'
import * as uuid from 'uuid'
import {Loader} from '../shared/loader/loader.component'
import {indexDatamap} from './../../models/indexData'
import { environment } from 'src/environments/environment'

type IndexCardProps = {}

export const IndexCardComponent: React.FunctionComponent<
  IndexCardProps
> = () => {
  const {data, loading, hasError} = useSelector(indexSelector)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchIndexData())
  }, [dispatch, hasError])

  const handleClick = (card: Card): void => {
    navigate(`/${indexDatamap.get(card.sourceCode)}`)
  }
  useEffect(() => {
    if (environment.maintenance?.mode === true) {
      navigate(environment.maintenance.path)
    }
  },[navigate])
  return (
    <div className="index-page">
      <div className="container">
        <div className="card-container">
          {!loading &&
            !hasError &&
            data.map((card: Card) => {
              console.log('Logs for Cards', card)
              return (
                card.sourceCode !== 'SBI' &&
                <div className="card-option" key={uuid.v4()}>
                  <h2 className="summary-heading">{card.sourceName}</h2>
                  <p className="summary-body">{card.summary} </p>
                  <button
                    className="btn-primary view-details-btn"
                    onClick={() => handleClick(card)}
                  >
                    View Details
                  </button>
                </div>
              )
            })}
        </div>
        {loading && !hasError && <Loader />}
        {!loading && hasError && (
          <div className="error-text-section">
            <span>Data Unavailable</span>
          </div>
        )}
      </div>
    </div>
  )
}
