import React, {useEffect, useState} from 'react'
import './drop-down.component.scss'
import {Select, MenuItem} from '@mui/material'

type DropDownProps = {
  isLoading?:boolean
  dropDownList: string[]
  updateSel: Function
  selectedItem: string
}
export const DropDown: React.FunctionComponent<DropDownProps> = ({
  dropDownList,
  updateSel,
  selectedItem,
  isLoading
}) => {
  const [defaultState, setDefaultState] = useState(selectedItem)

  const handleChange = (event: any) => {
    setDefaultState(event)
    updateSel(event)
  }
  useEffect(() => {
    if (isLoading === false) {
      setDefaultState(selectedItem)
    }
  },[selectedItem,isLoading])

  return (
    <div className="select-dropdown">
      <label>
        <Select
          data-testid='select-test-id'
          value={defaultState}
          onChange={event => handleChange(event.target.value)}
          autoWidth
          aria-label={selectedItem}
        >
          {dropDownList.map(option => {
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            )
          })}
        </Select>
      </label>
    </div>
  )
}
