export const environment = {
  name: 'PIMIND',
  env: 'local',
  production: false,
  maintenance: { mode: false, path: '/maintenance' },
  pimindApiUrl: '/pimind-api',
  // pimindApiUrl: 'http://localhost:8080/',
  year: new Date().getFullYear(),
  indexValue: "EFI"
}
