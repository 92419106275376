import axios from 'axios';
import { environment } from '../environments/environment';

if (window.APP_ENV) {
  Object.assign(environment, window.APP_ENV);
}

export const http = axios.create({
  baseURL: environment.pimindApiUrl,
  headers: {
    'Content-type': 'application/json',
  },
})

http.interceptors.request.use((config) => {
  return config;
})

http.interceptors.response.use((response) => { return response }, (error) => {
  return Promise.reject(error);
});


