import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export default function PieChart({data}: any) {
  ;(Highcharts as any).seriesTypes.line.prototype.drawLegendSymbol = (
    Highcharts as any
  ).seriesTypes.area.prototype.drawLegendSymbol
  const options = {
    chart: {
      renderTo: 'container',
      type: 'pie',
      height: 250,
      width: 300,
    },
    colors: [
      '#286DFF',
      '#00BFB3',
      '#0013BF',
      '#F36700',
      '#FFBC11',
      '#0BA713',
      '#6B48FF',
      '#FF5343',
      '#0BA713',
      '#00B5E2',
      '#DC1895',
      '#6FB13A',
      '#008DB7',
      '#C58C00',
      '#9B0675',
    ],
    yAxis: {},
    accessibility: {
      enabled: false
    },
    plotOptions: {
      pie: {
        center: ['50%'],
        shadow: false,
        borderWidth: 0,
      },
    },
    tooltip: {
      enabled: true,
    },
    series: [
      {
        name: 'Asset class Weight',
        data: data,
        size: '115%',
        innerSize: '55%',
        showInLegend: false,
        dataLabels: {
          enabled: false,
        },
      },
    ],
    legend: {
      enables: false,
    },
  }
  return (
    <>
      <div className="chart-loader-compare">
        <div className="loading-state-comp dots">
          <div className="lds-ellipsis"/>
          <div className="lds-ellipsis"/>
          <div className="lds-ellipsis"/>
          <div className="lds-ellipsis"/>
        </div>
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        allowChartUpdate={true}
        updateArgs={[true]}
        oneToOne={true}
      />
    </>
  )
}
