import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import {RootState} from '..'

export type SummaryCardState = {
    loading: boolean;
    data?: any;
    hasError:boolean
  };

export const initialState:SummaryCardState={
    loading:true,
    hasError:false,
}

const userSlice=createSlice({
    name:'users',
    initialState,
    reducers:{
        getSummmaryDataSuccess:(state,action:PayloadAction<any>)=>{
            state.data=action.payload;
            state.loading=false;
            state.hasError=false
        },
        startLoading:(state)=>{
            state.loading=true;
        },
        getSummaryFailure:(state)=>{
            state.loading=false;
            state.hasError=true;
        }
    }
})

export const {
    startLoading,
    getSummaryFailure,
    getSummmaryDataSuccess
}=userSlice.actions

export const summarySelector = (state:RootState)=>state.summary;

const summaryReducer=userSlice.reducer;

export default summaryReducer;


