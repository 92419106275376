import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {Loader} from '../../loader/loader.component'

import './line-chart.component.scss'

type lineChartProps = {
  data: object[] | []
  category: () => void
  asOfDate: string
  name: string
  isLoading: boolean
}

export default function LineChart({
  data,
  category,
  asOfDate,
  name,
  isLoading,
}: lineChartProps) {
  if (isLoading) {
    return <Loader />
  }
  ;(Highcharts as any).seriesTypes.line.prototype.drawLegendSymbol = (
    Highcharts as any
  ).seriesTypes.area.prototype.drawLegendSymbol
  const options = {
    chart: {
      height: '400',
      reflow: true,
      style: {
        fontFamily: 'Roboto condensed',
        fontSize: '14px',
      },
    },
    container: {
      spacingBottom: 40,
    },
    subtitle: {
      text: `As of (${asOfDate})`,
      align: 'right',
      floating: true,
      y: 20,
      style: {
        color: '#696E77',
      },
    },
    lang: {
      noData: 'Unable to load data for this section or No Data',
    },
    noData: {
      style: {
        width: 500,
        fontSize: '18px',
        color: '#D95443',
      },
      position: {
        x: 0,
        y: 0,
        align: 'center',
        verticalAlign: 'middle',
      },
    } as Highcharts.NoDataOptions,
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> {point.change}<br/>',
      changeDecimals: 2,
      valueDecimals: 2,
      backgroundColor: 'rgba(255, 255, 255, 1)',
      xDateFormat: '%m/%d/%Y',
      shape: "square"
    },
    legend: {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'top',
      symbolHeight: 12,
      symbolWidth: 12,
      itemMarginBottom: 10,
      symbolRadius: 2,
      fontSize: 14,
      itemStyle: {
        color: '#272B34',
        fontSize: 14
      },
      style: {
        'stroke-line-cap': 'square',
        symbolRadius: 0,
      },
    },
    rangeSelector: {
      allButtonsEnabled: true,
      selected: 4,
    },
    xAxis: {
      crosshair: true,
      type: 'datetime',
      labels: {
        rotation: -45,
        useHTML: false,
        style: {
          fontSize: '15px',
        },
      },
      dateTimeLabelFormats: {
        millisecond: '%b %e %Y',
        second: '%b %e %Y',
        minute: '%b %e %Y',
        hour: '%b %e %Y',
        day: '%b  %e %Y',
        week: '%b  %Y',
        month: '%b  %Y',
        year: '%Y',
      },
      tickInterval: category(),
    },

    series: [
      {
        name: `<span class='chart-series-name'>${name}</span>`,
        color: '#00BFB3',
        data: [...data],
        y: 20,
        marker: {
          enabled:false
        },
        style: {
          fontFamily: 'Roboto Condensed',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
          color: '#272B34',
        },
      },
    ],
    yAxis: {
      title: {
        text: 'Index Level',
        x:-10,
        style: {
          color: '#272B34',
        },
      },
      labels: {
        style: {
          color: '#272B34',
        },
      },
    },
    exporting: {
      enabled: false,
      allowHTML: true,
      sourceWidth: 1000,
      chartOptions: {
        legend: {
          itemStyle: {
            fontSize: '10px',
            textAlign: 'left',
            fontWeight: 'normal',
          },
        },
      },
    },
    accessibility: {
      enabled: false,
    },
    time: {
      useUTC: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            container: {
              spacingBottom: 40,
              spacingLeft: 24,
              spacingRight: 24,
            },
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
            subtitle: {
              floating: false,
              align: 'center',
              verticalAlign: 'top',
            },
          },
        },
      ],
    },
  }
  return (
    <div id='line-chart' className='line-chart-container'>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        allowChartUpdate={true}
        updateArgs={[true]}
        oneToOne={true}
      />
      </div>
  )
}
