import "./term-and-cond.component.scss";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import closeIcon from "src/assets/images/closeIcon.svg";

type TermandConddProps = {
  updateShowModal: Function,
  onClose: Function,
};

export const TermsandConditionsComponent: React.FunctionComponent<
  TermandConddProps
> = ({ updateShowModal, onClose }) => {
  const [checked, setChecked] = useState(false);
  const [showTool, setShowToo] = useState(true);

  const handleChange = () => {
    setShowToo(checked);
    setChecked(!checked);
  };

  const accepTNC = () => {
    if (checked) {
      localStorage.setItem("isAcceptedTNC", "true");
      updateShowModal();
    }
  };
  return (
    <Modal
      onClose={() => {
        onClose()
      }}
      open={true}
    >
      <div className="t-c-modal ">
        <div className="modal-header">
          <h3>Terms & Conditions</h3>
          <button
            className="close-modal"
            data-testid='close-tns'
            onClick={() => {
              onClose()
            }}
          >
            <img src={closeIcon} alt="logo" className="close-Icon " />
          </button>
        </div>
        <div className="modal-body">
          <h4>Please read the terms and conditions</h4>
          <p>
            The materials available here contain sophisticated financial
            research and educational information and are intended for investment
            professionals and other knowledgeable institutional investors.
            Nothing contained in these materials should be interpreted as
            investment advice or a recommendation of any particular security,
            strategy or investment product.
            <br />
            <br />
            By checking this box and proceeding you are certifying that you are
            an investment professional or other knowledgeable institutional
            investor capable of understanding these materials, evaluating
            investment risks independently and making your own investment
            decisions.
          </p>
          <div className="pim-checkbox">
            <input
              type="checkbox"
              id="tnc-checkbox"
              data-testid="tnc-checkbox"
              checked={checked}
              onChange={handleChange}
            />
            <label htmlFor="tnc-checkbox">
              I Agree to the Terms and Conditions
            </label>
          </div>
        </div>
        <div className="modal-footer">
          <div className="pa-btn-area">
            <div className="pim-btn">
              {showTool && (
                <div className="tooltip">
                  Check the box to agree to the Terms and Conditions
                </div>
              )}
              <button
                className={
                  checked
                    ? 'btn-primary'
                    : 'btn-primary continue-button-disabled'
                }
                onClick={() => { accepTNC() }}
              >
                {' '}
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
};
