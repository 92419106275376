import { combineReducers } from "@reduxjs/toolkit";


import summaryReducer from "./summary/summary.slice";
import indexReducer from "./indexData/index.slice";

const rootReducer=combineReducers({
    summary:summaryReducer,
    indexData:indexReducer
});

export type RootState =ReturnType<typeof rootReducer>;
export default rootReducer;