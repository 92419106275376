import {useState} from 'react'

import './index-data.component.scss'
import {HistoricalDataComponent} from '../../historical-data/historical-data.component'
import {AssetClassWeightDataComponent} from '../../asset-class-by-weight-historical/asset-class-historical.component'

type IndexDataProps = {}

export const IndexDataComponent: React.FunctionComponent<
  IndexDataProps
> = () => {
  const filter = [
    'Historical Data',
    'Asset Class Weights',
  ]

  const [filterInd, setFilterInd] = useState('Historical Data')
  return (
    <div className="index-container">
      <div className="detail-item">
        <div className="header">
          <h2>Index Data</h2>
        </div>
        <div className="tab-menu-nav">
          <div className="tabs">
            {filter.map(ind => (
              <button
                data-testid={`index-dropdown-${ind}`}
                className={ind === filterInd ? ' active' : ''}
                key={ind}
                onClick={() => {
                  setFilterInd(ind)
                }}
              >
                {ind}
              </button>
            ))}
          </div>
        </div>
        {filterInd === 'Historical Data' && <HistoricalDataComponent />}
        {filterInd === 'Asset Class Weights' && (
          <>
            <AssetClassWeightDataComponent />
            
          </>
        )}
        
      </div>
    </div>
  )
}
