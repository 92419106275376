export function ErrorPage(props: any) {

  return (
    <div data-testid="error-page">
      <h1>Oops!</h1>

      <p>Sorry, an unexpected error has occurred.</p>
      <p data-testid="error-message"><i>{props.error?.statusText || props.error?.message}</i></p>
    </div>
  );
}
