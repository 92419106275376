import { useState } from 'react'
import { ContactUsModalComponent } from './contact-us-modal/contact-us-modal-component'
import './contact-us.component.scss'
import speechIcon from '../../../assets/images/speech-bubble.svg'

export function ContactUsComponent() {
  
const [showModal, setShowModal] = useState(false)
  return (
    <>
      <div className="pim-btn contact-us">
        <button
          onClick={() => {
            setShowModal(true)
          }}
          className="contact-btn"
          data-testid="contact-modal-click-id"
        >
          <span className="cu-txt">Contact Us</span>
          <img className="cu-icon" src={speechIcon} alt="close-Icon" />
        </button>
      </div>
      {showModal && (
        <ContactUsModalComponent
          onClose={() => {
            setShowModal(false)
          }}
        />
      )}
    </>
  )
}
