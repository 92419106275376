import html2canvas from 'html2canvas'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _pdfExportService from 'src/app/services/pdfExport-service'
import pimindServices from 'src/app/services/pimind-services'
import { summarySelector } from 'src/app/slices/summary/summary.slice'
import { getId, isDate } from 'src/app/utils/utils'
import { GroupedButton } from '../../shared/button-group/btn-group.component'
import StackChart from '../../shared/charts/stack-chart/stack-chart.component'
import {indexesHidesBackTestData} from '../../shared/const-value';
import { Loader } from '../../shared/loader/loader.component'
import { AssetClassWeightByMonthComponent } from '../asset-class-weight-by-month/asset-class-weight-by-month-component'

import './asset-class-historical.component.scss'

type AssetClassWeigthDataProps = {}

type formType = {
  name: string
  historicalData: number[]
  historicalDate: string[]
  data: string[]
}
type chartDataType = {
  name: string
  data: number[][]
}
export const AssetClassWeightDataComponent: React.FunctionComponent<
  AssetClassWeigthDataProps
> = () => {
  const groupButton = ['1 Yr', '3 Yr', '5 Yr', '10 Yr', 'Max', 'Custom']
  const [selRangeBtn, setSelRangeBtn] = useState<string>('10 Yr')
  const [customStartDate, setCustomStartDate]: any = useState(null)
  const [customEndDate, setCustomEndDate]: any = useState(null)
  const [historicalData, setHistoricalData] = useState<formType[]>([])
  const [isLoading, setIsloading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [isDataAvailable, setIsDataAvailable] = useState(false)
  const [chartData, setchartData] = useState<chartDataType[]>([])
  const [asOfDate, setAsofDate] = useState('')
  const { data } = useSelector(summarySelector)
  const [isMaxCall, setIsMaxCall] = useState(false)
  const [duration, setDuration] = useState(0)
  const [chartSeriesName, setSeriesName] = useState('')
  const [formatDate,setFormattedData]=useState('')
  const [startDate, setStartDate] = useState('')
  useEffect(() => {
    const currentDate = new Date()
    currentDate.setDate(1); // going to 1st of the month
    currentDate.setHours(-1); //going to last month last date
    const endDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    ).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/');
    const startDate=new Date(
      currentDate.getFullYear(),
      currentDate.getMonth()-2,
      currentDate.getDate()
    ).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/');
    pimindServices.getLatestDate( `${startDate[2]}-${startDate[0]}-${startDate[1]}`,
          `${endDate[2]}-${endDate[0]}-${endDate[1]}`).then(
            (res: any) => {
        if (res.data) {
          const date = res.data?.asOfDate.split('T')[0].split('-')
      const updatedDate = `${date[1]}/${date[2]}/${date[0]}`
          setFormattedData(updatedDate)
          setAsofDate(updatedDate)
         }
      })
  }, [data])
  useEffect(() => {
    if (data?.asOfDate?.asOfDate !== undefined) {
      setSeriesName(data.data.sourceName)
    }
  }, [data,startDate])
  const getPDF = useCallback(async() => {
     const documentElement = document.getElementById("stack-chart")
       if (documentElement !== null) {
         html2canvas(documentElement, {scale: 5}).then(
            async (result: {toDataURL: (id: string) => any}) => {
              const dataURL = result.toDataURL('image/jpeg')
              await _pdfExportService.download_pdf_file(dataURL, asOfDate, startDate, chartSeriesName, data.data.sourceName,false,new Date(startDate).getTime() < new Date(data?.data?.launchDate).getTime(),false)
           },
         )
       }
  },[asOfDate, data, chartSeriesName,startDate])
  useEffect(() => {
    if (data) {
      const today = new Date()
      const startDate = new Date(
        today.getFullYear() - 10,
        today.getMonth(),
        today.getDate()
      )
      setStartDate(startDate.toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }))
      setIsloading(true)
      pimindServices
        .getassetClassHistorical(
          'Historical',
          startDate.toISOString().split('T')[0],
          today.toISOString().split('T')[0],
          false
        )
        .then(
          (res) => {
            if (res.data) {
              setIsDataAvailable(true)
              let data = []
              for (var keys in res.data) {
                let form: formType = {
                  name: '',
                  historicalData: [],
                  historicalDate: [],
                  data: [],
                }
                form.name = keys
                form.historicalData = res.data[keys].historicalData
                form.historicalDate = res.data[keys].historicalDates
                form.data = res.data[keys].historicalData
                data.push(form)
              }

              setHistoricalData([...data])
            } else{
              setIsloading(false)
              setIsDataAvailable(false)
              setIsError(true)
            }
          },
          () => {
            setIsloading(false)
            setIsDataAvailable(true)
            setIsError(true)
          }
        )
    }
  }, [data])
  const getSelectedDurationTickInterval = useCallback((): number => {
    let tickInterval: number = 0
    if (selRangeBtn === '1 Yr') {
      tickInterval = 2628000000
    } else if (selRangeBtn === '3 Yr') {
      tickInterval = 7884000000
    } else if (selRangeBtn === '5 Yr') {
      tickInterval = 7884000000
    } else if (selRangeBtn === '10 Yr') {
      tickInterval = 7884000000
    } else if (selRangeBtn === 'Max') {
      tickInterval = 31556952000
    } else if (selRangeBtn === 'Custom') {
      if (customStartDate && customEndDate) {
        const differenceInTime =
          customEndDate?.$d.getTime() - customStartDate?.$d.getTime()
        const noOfDays = differenceInTime / (1000 * 3600 * 24)
        if ( noOfDays <=365) {
          tickInterval = 604800000
        }  else if (noOfDays > 365 && noOfDays <= 3650) {
          tickInterval = 7884000000
        } else {
          tickInterval = 31556952000
        }
      } else {
        return duration
      }
    }
    return tickInterval;
  },[customEndDate, customStartDate, duration, selRangeBtn]);
   useEffect(() => {
    if (selRangeBtn !== 'Custom') {
      const tickInterval = getSelectedDurationTickInterval()
      setDuration(tickInterval)
    }
  },[selRangeBtn, getSelectedDurationTickInterval])
  const updateDuration = useCallback(() => {
    const updateAsofDate = () => {
      if (formatDate.length > 0) {
        setAsofDate(formatDate)
      }
    }

    const getCustomDateData = () => {
      setIsloading(true)
      setIsError(false)
      const startDate = customStartDate.$d.toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/')
      const endDate = customEndDate.$d.toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/')
      pimindServices
        .getLatestDate(
          `${startDate[2]}-${startDate[0]}-${startDate[1]}`,
          `${endDate[2]}-${endDate[0]}-${endDate[1]}`
        )
        .then(
          (res) => {
            const date = res.data?.asOfDate?.split('T')[0].split('-')
            const formatDte =`${date?.[1]}/${date?.[2]}/${date?.[0]}`
            setAsofDate(formatDte)
          },
          () => {
            setIsError(true)
            setIsDataAvailable(true)
          }
        )
      pimindServices
        .getassetClassHistorical(
          'Historical',
          `${startDate[2]}-${startDate[0]}-${startDate[1]}`,
          `${endDate[2]}-${endDate[0]}-${endDate[1]}`,
          true,
        )
        .then(
          res => {
            if(res.data){
              setIsDataAvailable(true)
            let data = []
            for (var keys in res.data) {
              let form: formType = {
                name: '',
                historicalData: [],
                historicalDate: [],
                data: [],
              }
              form.name = keys
              form.historicalData = res.data[keys].historicalData
              form.historicalDate = res.data[keys].historicalDates
              form.data = res.data[keys].historicalData
              data.push(form)
            }
            const result: { name: any,data:any }[] = []
            const priorDate = new Date(customStartDate)
            const preDate = new Date(customEndDate)
            for (let i = 0; i < data.length; i++) {
              const formattedDateList = data[i].historicalDate
              const dataList = data[i].historicalData
              const combineDate = []
              for (let j = 0; j < formattedDateList.length; j++) {
                const entry = new Date(formattedDateList[j])
                if (
                  entry.getTime() >= priorDate.getTime() &&
                  entry.getTime() <= preDate.getTime()
                ) {
                  combineDate.push([entry.getTime(), dataList[j]])
                }
              }
              combineDate.sort((a, b) => a[0] - b[0])
              combineDate.length>0 && setStartDate(new Date(combineDate[0][0]).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }))
              if (data[i]) {
                combineDate.sort((a, b) => a[0] - b[0])
                result.push({
                  name:`<span class='chart-series-name'>${data[i].name}</span>` ,
                  data: combineDate,
                })
              }
            }
              historicalData.forEach(el => {
              let flag = false
              for (let i = 0; i < result.length; i++) {
                if (result[i].name.includes(el.name)) {
                  flag = true
                  break
                }
              }
              if (!flag) {
                result.push({name:`<span class='chart-series-name'>${el.name}</span>`,data:[]})
              }
            })
              setchartData([...result])
              setIsDataAvailable(true)
              setIsError(false)
            setIsloading(false)
          } else{
            setIsloading(false)
            setIsDataAvailable(false)
            setIsError(true)
          }},
          () => {
            setIsloading(false)
            setIsError(true)
            setIsDataAvailable(true)
          },
        )
    }
    const getMaxDurationData = () => {
      updateAsofDate()
      setCustomEndDate(null)
      setCustomStartDate(null)
      setIsloading(true)
      if (isMaxCall) {
        let result = []
        for (let i = 0; i < historicalData.length; i++) {
          const formattedDateList = historicalData[i].historicalDate
          const dataList = historicalData[i].historicalData
          const combineDate = []
          for (let j = 0; j < formattedDateList.length; j++) {
            const entry = new Date(formattedDateList[j])
            combineDate.push([entry.getTime(), dataList[j]])
          }
          combineDate.sort((a, b) => a[0] - b[0])
          combineDate.length>0 && setStartDate(new Date(combineDate[0][0]).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }))
          result.push({ name: `<span class='chart-series-name'>${historicalData[i].name}</span>`, data: combineDate })
        }
        setchartData([...result])
        setIsDataAvailable(true)
        setIsError(false)
        setIsloading(false)
      } else {
        setIsloading(true)
        let startDate = getStartDateForInterval('max').toISOString().split('T')[0];
        pimindServices
          .getassetClassHistorical(
            'Historical',
            startDate,
            new Date().toISOString().split('T')[0],
            false
          )
          .then(
            (res) => {
              if (res.data) {
                setIsDataAvailable(true)
                let data = []
                for (var keys in res.data) {
                  let form: formType = {
                    name: '',
                    historicalData: [],
                    historicalDate: [],
                    data: [],
                  }
                  form.name = keys
                  form.historicalData = res.data[keys].historicalData
                  form.historicalDate = res.data[keys].historicalDates
                  form.data = res.data[keys].historicalData
                  data.push(form)
                }
                setHistoricalData([...data])
                setIsDataAvailable(true)
                setIsError(false)
                setIsMaxCall(true)
              }else {
                setIsloading(false)
                setIsDataAvailable(false)
                setIsError(true)
              }
            },
            () => {
              setIsloading(false)
              setIsError(true)
              setIsDataAvailable(true)
            }
          )
      }
    }

    const setTimeDuration = (duration: string) => {
      let startDate: Date = new Date()
      let endDate = new Date()
      let durationValue: number

      if (duration === '1 Yr') {
        startDate = new Date(
          endDate.getFullYear() - 1,
          endDate.getMonth(),
          endDate.getDate(),
        )
      } else if (duration === '3 Yr') {
        startDate = new Date(
          endDate.getFullYear() - 3,
          endDate.getMonth(),
          endDate.getDate(),
        )
      } else if (duration === '5 Yr') {
        startDate = new Date(
          endDate.getFullYear() - 5,
          endDate.getMonth(),
          endDate.getDate(),
        )
      } else if (duration === '10 Yr') {
        startDate = (indexesHidesBackTestData().includes(data.data.sourceCode)) ? new Date(data?.data?.launchDate) : new Date(
          endDate.getFullYear() - 10,
          endDate.getMonth(),
          endDate.getDate(),
        )
      }

      durationValue = Math.ceil(
        (endDate.getTime() -
          startDate.getTime() +
          endDate.getTimezoneOffset() * 60000) /
          (24 * 3600 * 1000),
      )
      return durationValue
    }
    const getSelectedDurationData = (): void => {
      updateAsofDate()
      setCustomEndDate(null)
      setCustomStartDate(null)
      setIsloading(true)
      const numberOfDays = setTimeDuration(selRangeBtn)
      let priorDate = new Date(Date.now() - numberOfDays * 24 * 60 * 60 * 1000)
      let result = []
      for (let i = 0; i < historicalData.length; i++) {
        const formattedDateList = historicalData[i].historicalDate
        const dataList = historicalData[i].historicalData
        const combineDate = []

        for (let j = 0; j < formattedDateList.length; j++) {
          const entry = new Date(formattedDateList[j])

          if (entry.getTime() >= priorDate.getTime()) {
            combineDate.push([entry.getTime(), dataList[j]])
          }
        }
        combineDate.sort((a, b) => a[0] - b[0])
        combineDate.length>0 && setStartDate(new Date(combineDate[0][0]).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }))
        result.push({ name:`<span class='chart-series-name'>${historicalData[i].name}</span>`, data: combineDate })
      }
      setchartData([...result])
      if (result.length > 0) {
        setIsDataAvailable(true)
        setIsError(false)
      } else {
        setIsDataAvailable(false)
      }
      setIsloading(false)
    }
    if (selRangeBtn === 'Custom') {
      if (customStartDate && customEndDate && isDate(customStartDate.$d) && isDate(customEndDate.$d)) {
        getCustomDateData();
      }
    } else if (selRangeBtn === 'Max') {
      setTimeDuration(selRangeBtn)
      getMaxDurationData()
    } else {
      setTimeDuration(selRangeBtn)
      getSelectedDurationData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selRangeBtn,
    data,
    customEndDate,
    customStartDate,
    historicalData,
    isMaxCall,
  ])
  useEffect(() => {
    updateDuration()
  }, [updateDuration])

  function getStartDateForInterval(interval: string): Date {

    let currentDate = new Date()
    switch (interval) {
      case '1 Mo':
        return new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          currentDate.getDate(),
        )
      case '3 Mo':
        return new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          currentDate.getDate(),
        )
      case '1 Yr':
        return new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate(),
        )
      case '5 Yr':
        return new Date(
          currentDate.getFullYear() - 5,
          currentDate.getMonth(),
          currentDate.getDate(),
        )
      case '10 Yr':
        return (indexesHidesBackTestData().includes(data.data.sourceCode)) ?new Date(data?.data?.launchDate) : new Date(
          currentDate.getFullYear() - 10,
          currentDate.getMonth(),
          currentDate.getDate(),
        )
       case 'max':
        return (indexesHidesBackTestData().includes(data.data.sourceCode)) ? new Date(data?.data?.launchDate) : new Date(1999, 11, 25)
      default:
        return new Date(1999, 11, 25)
    }
  }

  return (
    <>
      <div className="assetCntr">
        <div className="grp-btn">
          <h2 className="subHeading">
            Weights (Historical)
            {!isError ? (
              <span className="date-header">(As of {asOfDate} )</span>
            ) : (
              ''
            )}
          </h2>
          <div className="btnCntr">
            <GroupedButton
              buttonData={groupButton}
              selectedButton={selRangeBtn}
              updateSelectedItem={(buttonEl: string) => {
                buttonEl === 'Max' && setIsloading(true)
                setSelRangeBtn(buttonEl)
              }}
              customStartDate={customStartDate}
              customEndDate={customEndDate}
              updateStartDate={(date: Date) => {
                setCustomStartDate(date)
              }}
              updateEndDate={(date: Date) => {
                setCustomEndDate(date)
              }}
            />
              <button
                className="btn-tertiary export-btn"
                onClick={() => {
                  getPDF()
                }}
              >
                Export PDF
              </button>
          </div>
        </div>
        {!isLoading && !isError && (
            <StackChart
            setMax={getId()?.toLowerCase()==='pimbap'||getId()?.toLowerCase()==='gem'?false:true}
            data={chartData}
            category={getSelectedDurationTickInterval}
            asOfDate={asOfDate}
            isLoading={isLoading}
          />
        )}
        {isLoading && !isError && <Loader />}
        {!isDataAvailable && isError && !isLoading &&(
          <div className="data-not-found">
            <span>Data Unavailable</span>
          </div>
        )}
         {isError && !isLoading && isDataAvailable && (
        <div className="data-not-found">
          <span>Data Unavailable</span>
        </div>
      )}
        <div className="horizontal-row" />
      </div>

      <AssetClassWeightByMonthComponent showBackTestData={new Date(startDate).getTime() < new Date(data?.data?.launchDate).getTime() } />
    </>
  )
}
