import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { environment } from 'src/environments/environment'
import './maintenance-page.component.scss'

type MaintenancePageProps = {}

export const MaintenancePageComponent: React.FunctionComponent<
    MaintenancePageProps
    > = () => { 
        const navigate = useNavigate()
        useEffect(() => {
            if (environment.maintenance?.mode === false) {
            navigate('/')
            }
        },[navigate])
        return (<div className='maintenance-page' data-testid='maintenance-page'>
            <div  className='detail-item'>
            <h2 className='header'>Service Unavailable</h2>
                <p className='subtitle'>The server is temporarily busy.Please try again later.</p>
                </div>
    </div>)
}