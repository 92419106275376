import React from 'react';
import { useSelector } from "react-redux";
import { summarySelector } from "src/app/slices/summary/summary.slice";
import './tabs-disclosure.component.scss';

type TabsDisclosureProps = {
  showBackTestDisclosure:boolean
}

export const TabsDisclosureComponent: React.FunctionComponent<
  TabsDisclosureProps
    > = ({showBackTestDisclosure}) => {
        const { data } = useSelector(summarySelector);
  return (
    <div className="tabs-disclosure">
      <h3 className="tabs-disclosure-title">
        Hypothetical backtest for illustrative purposes only.
      </h3>
      {data && (
        <>
          <p className="tabs-disclosure-content">{data.data.disclosure}</p>
          {showBackTestDisclosure && (<>
          <p className="tabs-disclosure-content">
            HYPOTHETICAL PERFORMANCE RESULTS HAVE MANY INHERENT LIMITATIONS,
            SOME OF WHICH ARE DESCRIBED BELOW. NO REPRESENTATION IS BEING MADE
            THAT ANY ACCOUNT WILL OR IS LIKELY TO ACHIEVE PROFITS OR LOSSES
            SIMILAR TO THOSE SHOWN. IN FACT, THERE ARE FREQUENTLY SHARP
            DIFFERENCES BETWEEN HYPOTHETICAL PERFORMANCE RESULTS AND THE ACTUAL
            RESULTS SUBSEQUENTLY ACHIEVED BY ANY PARTICULAR TRADING PROGRAM.
          </p>
          <p className="tabs-disclosure-content">
            ONE OF THE LIMITATIONS OF HYPOTHETICAL PERFORMANCE RESULTS IS THAT
            THEY ARE GENERALLY PREPARED WITH THE BENEFIT OF HINDSIGHT. IN
            ADDITION, HYPOTHETICAL TRADING DOES NOT INVOLVE FINANCIAL RISK, AND
            NO HYPOTHETICAL TRADING RECORD CAN COMPLETELY ACCOUNT FOR THE IMPACT
            OF FINANCIAL RISK IN ACTUAL TRADING. FOR EXAMPLE, THE ABILITY TO
            WITHSTAND LOSSES OR TO ADHERE TO A PARTICULAR TRADING PROGRAM IN
            SPITE OF TRADING LOSSES ARE MATERIAL POINTS WHICH CAN ALSO ADVERSELY
            AFFECT ACTUAL TRADING RESULTS. THERE ARE NUMEROUS OTHER FACTORS
            RELATED TO THE MARKETS IN GENERAL OR TO THE IMPLEMENTATION OF ANY
            SPECIFIC TRADING PROGRAM WHICH CANNOT BE FULLY ACCOUNTED FOR IN THE
            PREPARATION OF HYPOTHETICAL PERFORMANCE RESULTS AND ALL OF WHICH CAN
            ADVERSELY AFFECT ACTUAL TRADING RESULTS
          </p>
            </>)}
          <p className="tabs-disclosure-title"> CMR2023-0117-2428415</p>
        </>
      )}
    </div>
  )
}
