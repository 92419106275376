import {useState} from 'react'
import { LegalDisclaimerModalComponent } from '../../index-detail/legal-disclaimer-modal/legal-disclaimer-modal.component'
import './privacy-policy.component.scss'
type PrivacyPolicyProps = {}

export const PrivacyPolicyComponent: React.FunctionComponent<
  PrivacyPolicyProps
> = () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <div className="links">
        <div className="privacy-policy">
          <a
            className="policy-link"
            target="_blank"
            rel="noreferrer"
            href="https://www.pimco.com/en-us/general/legal-pages/privacy-policy"
          >
            Privacy Policy
          </a>
        </div>
        &nbsp;|&nbsp;
        <div className="legal-disclaimer" role="button" onClick={() => setShowModal(true)}>
          Legal Disclaimer
        </div>
        {showModal && (
          <LegalDisclaimerModalComponent
            onClose={() => {
              setShowModal(false)
            }}
          />
        )}
      </div>
    </>
  )
}
