import React from 'react';
import './footer.component.scss'
import {SiteWideDisclosureComponent} from './disclosures/site-wide-disclosure.component';

export const FooterComponent:React.FunctionComponent=()=> {

  return (
    <footer className="footer" data-testid="component-footer-main">
      <SiteWideDisclosureComponent />
    </footer>
  )
}
