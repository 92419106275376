import {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link, useLocation} from 'react-router-dom'
import {summarySelector} from 'src/app/slices/summary/summary.slice'
import cancelIcon from './../../../assets/images/masthead-circle-close.svg'
import warningicon from './../../../assets/images/warning-wht.svg'
import './navbar.component.scss';

type NavbarComponentPops = {
  isError?:boolean
}

export const NavbarComponent: React.FunctionComponent<
  NavbarComponentPops
> = ({ isError }:NavbarComponentPops) => {
  const [title, setTitle] = useState('PIMCO Custom Indices')
  const [showCancelButton, SetShowCancelButton] = useState(false)
  const {data} = useSelector(summarySelector)
  const location = useLocation()

  const getTitle = useCallback(async () => {
    let loc = window.location
    if (loc.pathname === '/') {
      SetShowCancelButton(false)
      setTitle('PIMCO Custom Indices')
    } else {
      if (data?.data?.sourceName) {
        SetShowCancelButton(true)
        if (data?.data?.sourceName === 'PIMCO Tactical Balanced ER Index') {
          setTitle(data?.data?.sourceName + ` (TBIER)`)
        } else {
          setTitle(data?.data?.sourceName + ` (${data?.data?.sourceCode})`)
        }
      }
      else{
        SetShowCancelButton(true)
        setTitle('PIMCO Custom Indices')
      }
    }
  }, [data])

  useEffect(() => {
    getTitle()
  }, [getTitle, location])

  return (
    <header className="masthead navbar" data-test="component-navbar">
      <div className={isError?"container notFound-header-container":'container'}>
      {isError === true ?
          <>
            <img src={warningicon} alt="close-button" />
            <h1 className="navbar-brand">Unexpected Error</h1>
        </> : <>
        <h1 className="navbar-brand">
          {title}
        </h1>
        {showCancelButton && (
          <div className="cancelButton">
            <Link to="/">
              <button className="close-btn">
                <img src={cancelIcon} alt="close-button" className="close-Icon " />
              </button>
            </Link>
          </div>
          )}
          </>}
        </div>
    </header>
  )
}
