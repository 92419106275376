import React, {useCallback, useEffect, useState} from 'react'
import {CharacteristicCardComponent} from './cards/characteristic-card/characteristics-card.component'
import './index-detail.component.scss'
import {IndexDataComponent} from './cards/index-data/index-data.component'
import {SummaryCardComponent} from './cards/summary-card/summary-card.component'
import pimindServices from 'src/app/services/pimind-services'
import {useAppDispatch} from 'src/app/store/store'
import {getSummmaryDataSuccess} from 'src/app/slices/summary/summary.slice'
import {getId} from 'src/app/utils/utils'
import {Summary} from 'src/app/models'
import { DocumentCardComponent } from './cards/document-card/document-card.component'
import { fetchIndexData, indexSelector } from 'src/app/slices/indexData/index.slice'
import { useNavigate, useLocation } from 'react-router-dom'
import { environment } from 'src/environments/environment'
import { getIndexDataKey, getIndexDataValue } from 'src/app/models/indexData'
import { NotFoundPage } from '../not-found/not-found.component'
import { useSelector } from 'react-redux'

type IndexCardProps = {}

export const IndexDetailComponent: React.FunctionComponent<
  IndexCardProps
  > = () => {
  const {hasError} = useSelector(indexSelector)
  const [summaryData, setSummaryData] = useState({})
  const [catData, setCatData] = useState({})
  const [isLoading, setIsloading] = useState(true)
  const [isError, setISError] = useState(false)
  const dispatch = useAppDispatch()
  const [refresh, setRefresh] = useState(false)
   const [isSummLoading, setIsSummloading] = useState(true)
  const location = useLocation()
    const navigate = useNavigate()
     const getSummary = useCallback(() => {
    const id = getId()
    const indexData: string | null = localStorage.getItem('indexData')
    if (JSON.parse(indexData!) !== null && (JSON.parse(indexData!)).length) {
      const summaryData = (JSON.parse(indexData!)).filter(
        (details: Summary) => details.sourceCode === id,
      )
      setIsSummloading(false)
      setRefresh(false)
      setSummaryData(summaryData[0])
    } else if (hasError) {
      setIsSummloading(false)
    } else {
      setRefresh(true)

    }
  },[hasError])
  useEffect(() => {
    getSummary()
  }, [navigate,getSummary])   
  useEffect(() => {
    const newParam = getIndexDataKey(location.pathname.slice(1).toLowerCase())
    if (!newParam && location.pathname.slice(1).toLowerCase() !== '404') {
      const tabs=['tab1','tab2','tab1#','tab2#'];
      if(location.pathname.slice(1).toLowerCase() === 'pages/data.aspx' && getIndexDataValue(window.location.href.split('?')[1].split('=')[1].split('&')[0].toLowerCase()) === 'sbi' ) {
        navigate(
          `../${getIndexDataValue(
            window.location.href
              .split('?')[1]
              .split('=')[1]
              .split('&')[0]
              .toLowerCase(),
          )}`,
          {replace: true},
        )
      }
      else if (location.pathname.slice(1).toLowerCase() === 'pages/data.aspx' &&
        getIndexDataValue(window.location.href.split('?')[1].split('=')[1].split('&')[0].toLowerCase()) &&
        tabs.includes(window.location.href.split('?')[1].split('=')[2])) {
          navigate(`../${getIndexDataValue(window.location.href.split('?')[1].split('=')[1].split('&')[0].toLowerCase())}`,{replace:true,})
      } else {
        navigate('/404', { replace: true})
      }

      }
  },[location.pathname,navigate])
  useEffect(() => {
    if (location.pathname !== '/' && location.pathname.endsWith('/')) {
      const newPath = location.pathname.slice(0, -1);
      navigate(newPath,{replace:true});
    }
  },[location.pathname,navigate])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (summaryData) {
      dispatch(getSummmaryDataSuccess({data: summaryData, asOfDate: catData}))
    }
    if (refresh) {
      dispatch(fetchIndexData())
      getSummary()
    }
  }, [dispatch, summaryData, catData, refresh,isError,getSummary])
  const isSolactive = () => {
    const id = getId();
    return !(id === 'TBI' || id === 'TBIR' || id === 'SBI')
  }

  const getCharacteristics = useCallback(() => {
    setIsloading(true)
    pimindServices.getAsofDate().then(
      res => {
         const startDate = isSolactive()
          ? res.data[0].latestDate.split('T')[0]
          : res.data[2].latestDate.split('T')[0]
        const endDate = isSolactive()
          ? res.data[0].latestDate.split('T')[0]
          : res.data[2].latestDate.split('T')[0]

        pimindServices
          .getCharacteristicData(
            startDate,endDate
          )
          .then(
            response => {
              if (response.data.length > 0) {
                setIsloading(false)
                setISError(false)
                setCatData({ data: response.data, asOfDate: startDate });
              } else {
                setCatData({ data: {}, asOfDate: startDate });
                setIsloading(false)
                setISError(true)
              }
            },
            () => {
              setCatData({data: {}, asOfDate: startDate})
              setIsloading(false)
              setISError(true)
            },
          )
      },
      () => {
        setIsloading(false)
        setISError(true)
      },
    )
  }, [])

  useEffect(() => {
    getCharacteristics()
  }, [getCharacteristics,navigate])

 
  useEffect(() => {
    if (environment.maintenance?.mode === true) {
      navigate(environment.maintenance.path)
    }
    return () => {
      dispatch(getSummmaryDataSuccess({ data: {}, asOfDate: {} }))
    }
  },[navigate,dispatch])
  if (location.pathname.slice(1).toLowerCase()==='404') {
    return (<NotFoundPage/>)
  }

  return (
    <main>
      <div className="detail-page" data-testid="component-index-detail">
        <div className="container">
          <div className="card-container">
            <SummaryCardComponent summaryData={summaryData} loading={isSummLoading} />
            <CharacteristicCardComponent
              catData={catData}
              isLoading={isLoading}
              isError={isError}
            />
            <DocumentCardComponent />
          </div>
          <IndexDataComponent />
        </div>
      </div>
    </main>
  )
}


