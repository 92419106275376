import './document-card.component.scss'
import Document from 'src/assets/images/document.svg'
import {useEffect, useState} from 'react'
import {TermsandConditionsComponent} from '../../term-and-condition-modal/term-and-cond.component'
import pimindServices from 'src/app/services/pimind-services'
import {useSelector} from 'react-redux'
import {summarySelector} from 'src/app/slices/summary/summary.slice'
import {Loader} from 'src/app/components/shared/loader/loader.component'

type DocumentCardProps = {}

export const DocumentCardComponent: React.FunctionComponent<
  DocumentCardProps
> = () => {
  const [showModal, setShowModal] = useState(false)
  const [selectedDoc, setSelDoc] = useState('')
  const {data} = useSelector(summarySelector)
  const [isloading, setIsloading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [containSheet, setContainSheet] = useState(false)
  const [containBrochure, setContainBrochure] = useState(false)

  const fetchdocument = (docsName: string) => {
    let isAccepted = localStorage.getItem('isAcceptedTNC')
    setSelDoc(docsName)
    if (isAccepted) {
      setIsloading(true)
      if (data.data.documents) {
        const fileToFetch = data.data.documents.split(',')
        let docName: string | String
        if (fileToFetch.length === 1) {
          docName = fileToFetch[0].slice(0, fileToFetch[0].length - 4)
        } else {
          docName =
            docsName === 'Monthly Fact Sheet'
              ? fileToFetch[1].slice(0, fileToFetch[1].length - 4)
              : fileToFetch[0].slice(0, fileToFetch[0].length - 4)
        }
        setShowModal(false)
        pimindServices.getDocument(docName).then(
          res => {
            if (res.data.size === 0) {
              setIsloading(false)
              setIsError(true)
            } else {
              setIsError(false)
              const file = new Blob([res.data], {type: 'application/pdf'})
              const fileURL = URL.createObjectURL(file)
              const link = document.createElement('a')
              const fileName =
                docsName === 'Monthly Fact Sheet'
                  ? docName.slice(6, -11) + ' Monthly Fact sheet'
                  : docName.slice(6)
              link.setAttribute('target', '_blank')
              link.setAttribute('href', fileURL)
              link.setAttribute('download', fileName)
              link.click()
              link.remove()
              setIsloading(false)
            }
          },
          () => {
            setIsloading(false)
            setIsError(true)
          },
        )
      }
    } else {
      setShowModal(true)
    }
  }

  useEffect(() => {
    setContainBrochure(false)
    setContainSheet(false)
    if (data?.data?.documents) {
      const fileToFetch = data.data.documents.split(',')
      if (fileToFetch.length === 1) {
        const fileType = fileToFetch[0].split(' ')
        if (fileType[fileType.length - 1] === 'Brochure.pdf') {
          setContainBrochure(true)
        } else if (fileType[fileType.length - 1] === 'Sheet.pdf') {
          setContainSheet(true)
        }
      } else {
        setContainBrochure(true)
        setContainSheet(true)
      }
    }
  }, [data])

  return (
    <div className="document-container">
      <div className="detail-item">
        <div className="group-title">
          <h2>Documents</h2>
        </div>
        {!(data?.data?.documents === null) ? (
          <div className="detail-content">
            <div className="perf-table">
              <table className="pim-table">
                <thead>
                  <tr>
                    <th colSpan={2}>Documents</th>
                  </tr>
                </thead>
                <>
                  <tbody>
                    {!isloading && containBrochure && (
                      <tr role="row">
                        <td>
                          <div className="doc-btn-cntr row-first">
                            <img src={Document} alt="document icon" />{' '}
                            <button
                              onClick={() => {
                                fetchdocument('Index Brochure')
                              }}
                              className="open-doc"
                            >
                              Index Brochure
                            </button>
                            {isError && selectedDoc === 'Index Brochure' && (
                              <div className="data-not-found">
                                <span>
                                  This file is currently unavailable. Please
                                  reach out to your PIMCO representative with
                                  any questions.
                                </span>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                    {!isloading && containSheet && (
                      <tr>
                        <td>
                          <div className="doc-btn-cntr">
                            <img src={Document} alt="document icon" />{' '}
                            <button
                              onClick={() => {
                                fetchdocument('Monthly Fact Sheet')
                              }}
                              className="open-doc"
                            >
                              Monthly Fact Sheet
                            </button>
                            {isError &&
                              selectedDoc === 'Monthly Fact Sheet' && (
                                <div className="data-not-found">
                                  <span>
                                    This file is currently unavailable. Please
                                    reach out to your PIMCO representative with
                                    any questions.
                                  </span>
                                </div>
                              )}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </>
              </table>
              {isloading && <Loader />}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {showModal && (
        <TermsandConditionsComponent
          updateShowModal={() => {
            fetchdocument(selectedDoc)
          }}
          onClose={() => {
            setShowModal(false)
          }}
        />
      )}
    </div>
  )
}
