import {createRoot} from 'react-dom/client';
import {environment} from "./environments/environment";
import * as serviceWorker from './serviceWorker';

import './index.scss';
import {App} from './app/app';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './app/store/store';
import { datadogRum } from '@datadog/browser-rum';


if (window.APP_ENV) {
  Object.assign(environment, window.APP_ENV)
}

//dataDog configuration -- allows for ux monitoring
datadogRum.init({
  applicationId: 'f51aceea-d9f7-45e6-a30f-71df3025e377',
  clientToken: 'pubb3705e533fd524cc5461ba1d4beb76f2',
  site: 'datadoghq.com',
  service:'cf-tech-pimco-index',
  env: environment.env,
  version: '1.2.1',
  sessionSampleRate:100,
  sessionReplaySampleRate: 10,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel:'mask-user-input'
});

datadogRum.startSessionReplayRecording();


const root = createRoot(document.getElementById('root') as HTMLDivElement, {});

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();



