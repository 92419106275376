import {Modal} from '@mui/material'
import React from 'react'
import closeIcon from 'src/assets/images/closeIcon.svg';
import './legal-diclaimer-modal-component.scss'

type LegalDisclaimerModalProps = {
  onClose: Function
}
export const LegalDisclaimerModalComponent: React.FunctionComponent<
  LegalDisclaimerModalProps
> = ({onClose}) => {
  return (
    <div>
      <Modal onClose={() => onClose()} open={true}>
        <div className="legal-disclaimer">
          <div className="t-c-modal ">
            <div className="modal-header">
              <h3>Legal Information</h3>
              <button
                className="close-modal"
                data-testid="legal-close-button"
                onClick={() => onClose()}
              >
                <img src={closeIcon} alt="logo" className="close-Icon " />
              </button>
            </div>
            <div className="modal-body">
              <p>
                Please read these Terms and Conditions of Use ("Terms and
                Conditions") carefully. This is not an offer of securities to
                any person in any jurisdiction where it is unlawful or
                unauthorized. For the most part, PIMCO provides services to
                qualified institutions, financial intermediaries and
                institutional investors. Individual investors should contact
                their own financial professional to determine the most
                appropriate investment options for their financial situation.
                This Site includes information provided by Pacific Investment
                Management Company LLC.
                <span className="bold-text">
                  &nbsp;Investment Management Company LLC
                </span>{' '}
                , 650 Newport Center Drive, Newport Beach, CA 92660 is regulated
                by the United States Securities and Exchange Commission. The
                above financial institution, referred herein as “PIMCO” own and
                operate this website (“Site”). The information provided on this
                Site is not intended for distribution to, or use by, any person
                or entity in any jurisdiction where such distribution or use
                would be contrary to law or regulation or which would subject
                PIMCO or any of PIMCO’s products or services to any
                authorization, registration, licensing or notification
                requirement within any jurisdiction outside of those
                jurisdictions in which the underlying financial institutions are
                licensed to operate. This Site has been created for
                informational purposes.
              </p>
              <br />
              <p>
                By registering for, logging into, visiting and/or otherwise
                using the Site in any way, you indicate that you understand and
                intend these Terms and Conditions to be the legal equivalent of
                a signed, written contract, and you accept the terms of use as
                set forth herein and agree to be legally bound by them. If you
                do not agree to these Terms and Conditions, please do not access
                this website or any pages thereof. By accessing or using this
                Site, Data or Content (each, as defined below), you agree to be
                bound by these Terms and Conditions. These Terms and Conditions
                govern your access to and use of the Site operated by PIMCO, and
                of any text, music and sound, graphics, images, photographs,
                audio and video, footage, animations, podcasts, analyses,
                studies, reports, and downloads or other materials appearing on
                the Site ("Content") or data pages and downloads ("Data").
              </p>
              <h4>Restrictions</h4>
              <p>
                Unless and except as otherwise expressly permitted by these
                Terms and Conditions, you may not (a) access, copy, reproduce,
                modify, create derivative works of, alter, publicly distribute,
                republish, upload, post, transmit, publicly perform or display,
                license, transfer, sell, mirror, frame, “deep link,” “scrape,”
                data mine, or otherwise use any information or material obtained
                from or through this Site (b) attempt to gain unauthorized
                access to any portion or feature of the Site or any other
                systems or networks connected to the Site or to any PIMCO server
                or to any of the services offered on or through the Site, by
                hacking, password "mining," or any other illegitimate or
                prohibited means, (c) probe, scan, or test the vulnerability of
                the Site or any network connected to the Site, nor breach the
                security or authentication measures on the Site or any network
                connected to the Site, (d) reverse look-up, trace, or seek to
                trace any information on any other user of or visitor to the
                Site, (e) take any action that imposes an unreasonable or
                disproportionately large load on the infrastructure of the Site
                or PIMCO’s systems or networks or any systems or networks
                connected to the Site, (f) use any device, software, or routine
                to interfere with the proper working of the Site or any
                transaction conducted on the Site, or with any other person’s
                use of the Site, (g) forge headers, impersonate a person, or
                otherwise manipulate identifiers in order to disguise your
                identity or the origin of any message or transmittal you send to
                PIMCO on or through the Site, or (h) use the Site in an unlawful
                manner. Any unauthorized use of this Site or the content on the
                Site will terminate the permission granted herein and may
                violate applicable laws, including copyright law, trademark
                laws, and commercial regulations and statutes.
              </p>
              <h4>Reservation of Rights</h4>
              <p>
                We reserve the right, at our discretion, to change, modify, add,
                or remove portions of these Terms and Conditions at any time.
                Your continued use of the Site following such modifications will
                be conclusively deemed acceptance of any changes to these Terms
                and Conditions. You agree that notice of changes to these Terms
                and Conditions posted on the Site constitutes reasonable and
                sufficient notice. At all times, you are bound by the
                then-current version of the Terms and Conditions and of all
                applicable laws.
              </p>
              <h4>Use of Content</h4>
              <p>
                The Content of this Site is available for informational purposes
                only. The posting of Content and access to this Site does not
                constitute, either explicitly or implicitly, any provision of
                services or products by PIMCO or any of their respective
                affiliates. PIMCO updates the Content and Data from time to time
                at its sole discretion. You agree to check this Site regularly
                for updates. You agree to use all Content unmodified and as
                provided by PIMCO and with all logos and copyright, trade name,
                and other proprietary rights notices provided by PIMCO (the
                "Marks") intact and to not modify the Content, including but not
                limited to by removing, altering, or adding any text, graphics,
                warranty statements, logos, or Marks. You will not re-brand or
                co-brand the Content or Data. You agree to use of Data as-is and
                not alter or distribute Data. You will not take any action in
                derogation of PIMCO's rights in the Marks, including but not
                limited to applying to register any trademark, trade name,
                service mark, or other designation that is confusingly similar
                to any Mark. You will not use the Marks for any purpose.
              </p>
              <br />
              <p>
                You will use only Content designated for you and will not
                distribute Content to any third parties.
              </p>
              <h4>No Relationship</h4>
              <p>
                PIMCO is an independent contractor and not an agent of you in
                the performance of these Terms and Conditions. These Terms and
                Conditions are not to be interpreted as evidence of an
                association, joint venture, partnership, or franchise between
                the parties. Nothing in these Terms and Conditions will be
                deemed to confer any third-party rights or benefits.
              </p>
              <h4>Copyright and Trademarks</h4>
              <p>
                This Site (including the software and files), the computers
                hosting this Site, Content and Data provided on this Site are
                the exclusive property of PIMCO and/or its licensors, and are
                protected by applicable laws, including United States copyright,
                database, trade secret, and trademark law, as well as other
                state, national, and international laws and regulations. PIMCO
                is a trademark or registered trademark of Allianz Asset
                Management of America LLC in the United States and throughout
                the world. PIMCO reserves all rights with respect to its
                proprietary information or material on this Site and will
                enforce such rights to the full extent of applicable copyright
                and trademark law. Except as expressly provided in these Terms
                and Conditions, PIMCO does not grant any express or implied
                rights to you. All rights in the pages and Site Content are
                owned by PIMCO and its licensors. You agree not to sell,
                distribute, publish, broadcast, circulate or commercially
                exploit Site Content in any manner without the express written
                consent of PIMCO. Any downloading or otherwise copying from this
                Site will not transfer title to any software or material to you.
                Unless otherwise noted PIMCO and other brands featured on the
                Site constitute our trade/service marks. You agree not to
                directly or indirectly: attempt to register, challenge or
                contest the validity of PIMCO’s ownership of, such copyrights,
                trademarks or any other intellectual property of any PIMCO
                Company, or assist any third party in doing so.
              </p>
              <h4>Grant of License</h4>
              <p>
                PIMCO grants to you a personal, revocable, nonexclusive,
                nontransferable license to use this Site conditioned on your
                continued compliance with these Terms and Conditions. Limited
                portions of the Content available in the public areas of the
                Site may be accessed, printed and downloaded on an occasional
                basis for personal, scholarly, research or non-commercial
                purposes; provided that all hard copies contain all copyright
                and other applicable proprietary rights notices included in such
                content and information and/or appropriate attribution to PIMCO
                in a form and manner acceptable to us. For information on
                obtaining permission to use any Content beyond the uses
                permitted above, please contact PIMCO - 650 Newport Center
                Drive, Newport Beach, CA 92660, 800-387-4626. Any unauthorized
                use of this Site or the Content will terminate the permission
                granted herein and may violate applicable laws, including
                copyright law, trademark laws, and commercial regulations and
                statutes.
              </p>
              <h4>No Investment Advice</h4>
              <p>
                This Site provides information concerning indices licensed by
                PIMCO (“Licensed PIMCO Indices”). It is not possible to invest
                directly in an unmanaged index. Nothing contained herein
                constitutes an offer, solicitation or recommendation regarding
                any investment management product or service, or the offer to
                sell or the solicitation of an offer to buy any security. This
                Site is published in the United States for residents of the
                United States. The Site is not intended for use by any person in
                any jurisdiction where (by reason of that person's nationality,
                residence or otherwise) the publication or availability of the
                information on this Site is prohibited. It is the responsibility
                of any person accessing this Site to inform himself of, and to
                observe fully, any restrictions or prohibitions of the
                applicable laws and regulations of any relevant jurisdiction.
              </p>
              <h4>No Reliance</h4>
              <p>
                Although PIMCO has taken reasonable care to ensure that the
                information contained within this Site is accurate, and current
                as of the date stated no representation or warranty (including
                liability towards third parties), expressed or implied, is made
                as to its accuracy, reliability or completeness by PIMCO or
                their contractual partners. Certain resources available herein
                rely on third party information. PIMCO has not independently
                verified the third party data. Those viewing the content should
                not rely on it for any investment or other purposes. Opinions
                and any other contents on the Site are provided by PIMCO for
                personal use and informational purposes only and are subject to
                change without notice. Nothing contained on this Site
                constitutes investment, legal, tax or other advice and is not to
                be relied on in making an investment or other decision.
              </p>
              <h4>No Warranty</h4>
              <p>
                The information and opinions contained on this Site, including
                hyperlinks or references to other sites, are provided ‘as is’
                without any warranty of any kind, either expressed or implied,
                and PIMCO does not in any way warrant and hereby disclaims the
                accuracy, reliability, completeness, usefulness,
                non-infringement or quality of the Site to the fullest extent
                permissible pursuant to applicable law. You are responsible for
                evaluating the adequacy, accuracy, reliability, merchantability,
                non-infringement, completeness of any information or the content
                available on the Site or fitness for any particular purpose with
                respect to the site or any of its content. PIMCO further assumes
                no responsibility for, and makes no warranties that, functions
                contained on this Site will be uninterrupted or error-free, that
                defects will be corrected, or that this Site or the servers that
                make it available will be free of viruses or other harmful
                components. The Site, Data, and Content, as defined above, has
                been prepared based upon sources, materials, and systems
                believed to be reliable and accurate, and are provided to you on
                an “as is” and “as available” basis. PIMCO content providers
                make no representations, and disclaim all expressed, implied,
                and statutory warranties of any kind to you or any third party,
                including but not limited to representations and warranties
                regarding accuracy, timelines, completeness, merchantability,
                fitness for any particular purpose, non-infringement of
                third-party rights, and freedom from computer viruses. PIMCO
                content providers and other providers assume no responsibility
                for the consequences of any errors or omissions in, or delay or
                inability to access, the Site, Data, or Content. No advice or
                information, whether oral or written, obtained from PIMCO
                (outside of a formal written agreement) or through the Site,
                Data or Content will create any warranty not expressly made in
                these terms and conditions. PIMCO reserves the right to cease
                providing, or to change, the Site, Data or content (or any
                portion or feature thereof) at any time or frequency and without
                notice.
              </p>
              <h4>Fair Practices</h4>
              <p>
                You agree not to: (i) make any representations, warranties or
                guarantees on PIMCO’s behalf or with respect to the Site,
                Content or Data; (ii) make any false or misleading
                representations with regard to PIMCO, the Site, Data or Content;
                or (iii) participate or engage in any illegal, deceptive,
                misleading, unethical, or improper practices.
              </p>
              <h4>Liability Waiver</h4>
              <p>
                To the maximum extent permitted by law, in no event shall PIMCO
                or any of their respective affiliates, directors, officers,
                registered representatives or employees, nor any third party
                vendor, be liable or have any liability, whether in contract,
                tort, strict liability or otherwise, for any indirect,
                incidental, consequential, punitive or special damages including
                lost profits arising out of or in any way connected with your
                access or use or inability to access or use the Site or reliance
                on the Content, or any failure of performance, interruption,
                defect, delay in transmission, computer viruses or other harmful
                components, or line or system failure associated with the Site,
                regardless of our knowledge thereof. The same is true for any
                content appearing on any of PIMCO websites.
              </p>
              <br />
              <p>
                PIMCO disclaims all warranties, express or implied, including
                all warranties of merchantability or fitness for a particular
                purpose or use. PIMCO shall have no responsibility or liability
                whatsoever with respect to any Product managed to a Licensed
                PIMCO Index.
              </p>
              <br />
              <p>
                The Licensed PIMCO Indices are comprised of a number of
                constituents, some of which may be owned by entities other than
                PIMCO. The Licensed PIMCO Indices rely on a variety of
                publically available data and information and licensable equity
                and fixed income sub-indices.
              </p>
              <br />
              <p>
                The Indices are the property of Pacific Investment Management
                Company LLC ("PIMCO"). The Index will be calculated
                independently from PIMCO. It is not possible to invest directly
                in an unmanaged index.
              </p>
              <h4>Indemnity</h4>
              <p>
                You agree to indemnify, defend, and hold PIMCO and its
                subsidiaries, affiliates, officers, agents, and employees
                harmless from any direct and third party claims, and any related
                damages, losses or costs (including reasonable attorney fees and
                costs), arising out of, related to, or in connection with your
                use of the Site, your violation of these Terms and Conditions,
                or your violation of any rights of a third party.
              </p>
              <h4>Events Beyond PIMCO’s Control</h4>
              <p>
                You expressly absolve and release PIMCO, their respective
                affiliates, related companies, and all of their respective
                officers, directors, employees, registered representatives,
                shareholders, legal representatives, attorneys, agents,
                successors and assigns, from any claim of harm resulting from a
                cause beyond its control including, but not limited to, the
                failure of electronic or mechanical equipment or communication
                lines, telephone or other interconnect problems, computer
                viruses or software failures, data breaches, unauthorized
                access, theft, operator errors, severe weather, earthquakes, or
                natural disasters, pandemic or epidemic, strikes or other labor
                problems, wars, acts of domestic or foreign terrorism or
                governmental restrictions.
              </p>
              <h4>User Representations</h4>
              <p>
                You represent and warrant to PIMCO that you will not use this
                Site for any purpose that is unlawful or prohibited by these
                Terms and Conditions, including but not limited to attempting or
                actually (a) disrupting, impairing or otherwise interfering with
                the operation or integrity of the Site or circumventing any Site
                security system; (b) collecting any information about other
                users of the Site; or (c) systematically extracting Content or
                other information or data contained in the Site to populate
                databases or other sites.
              </p>
              <h4>Information Submitted By You</h4>
              <p>
                You should not submit any confidential or proprietary
                information via the Site. All information or material submitted
                through the Site will be deemed to be the property of PIMCO. By
                submitting any information or material to us through the Site,
                you grant us an unrestricted, irrevocable license to use,
                reproduce, display, perform, modify, transmit and distribute
                those materials or information. You also agree that PIMCO is
                free to use for any purpose any ideas, concepts, know-how or
                techniques that you submit to us through the Site. PIMCO shall
                not be subject to any obligations of confidentiality regarding
                submitted information, except as agreed by PIMCO having a direct
                customer relationship or as otherwise specifically agreed or
                required by law.
              </p>
              <br />
              <p>
                You can find more information about how we use and store content
                and about the collection and use of personal information by
                reviewing our{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.pimco.com/en-us/general/legal-pages/privacy-policy"
                  className="privacy-link"
                >
                  Privacy Policy
                </a>{' '}
              </p>
              <h4>Linked Sites</h4>
              <p>
                Certain hyperlinks or referenced websites on the Site may for
                your convenience forward you to third parties' websites, which
                generally are recognized by their top level domain name. Any
                descriptions of, references to, or links to other products,
                publications or services does not constitute an endorsement,
                authorization, sponsorship by or affiliation with PIMCO with
                respect to any linked site or its sponsor, unless expressly
                stated by PIMCO. Any such information, products or sites have
                not necessarily been reviewed by PIMCO and are provided or
                maintained by third parties over whom PIMCO exercises no
                control. PIMCO expressly disclaims any responsibility for the
                content, the accuracy of the information, and/or quality of
                products or services provided by or advertised on these
                third-party sites. PIMCO reserves the right to terminate any
                hyperlink or hyperlinking program at any time.
              </p>
              <h4>Jurisdiction, Severability</h4>
              <p>
                Any action arising out of these Terms and Conditions or the Site
                shall be litigated in, and only in, courts located in
                California, and you agree to submit to the exclusive
                jurisdiction of those courts and further agree that they are a
                convenient forum for you.
              </p>
              <br />
              <p>
                In the event that any provision of these terms is held
                unenforceable, the validity or enforceability of the remaining
                provisions will not be affected, and the unenforceable provision
                will be replaced with an enforceable provision that comes close
                to the intention underlying the unenforceable provision.
              </p>
              <br />
              <p>
                If you have any questions regarding these Terms and Conditions
                and/or the Site, please contact PIMCO - 650 Newport Center
                Drive, Newport Beach, CA 92660, 800-387-4626.
              </p>
              <br />
              <p>Dated: December 2011</p>
              <br />
              <p>Revised: December 2022</p>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
