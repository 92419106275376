import React from 'react';
import logo from '../../../../assets/images/allianz-logo.svg';
import './site-wide-disclosure.component.scss';
import {environment} from 'src/environments/environment';
import {PrivacyPolicyComponent} from "../privacy-policy/privacy-policy.component";

export const SiteWideDisclosureComponent:React.FunctionComponent=()=> {

  return (
    <div className="site-wide-disclosure" data-testid="component-footer">
      <p>
        PIMCO as a general matter provides services to qualified institutions, financial intermediaries and institutional
        investors. Individual investors should contact their own financial professional to determine the most
        appropriate investment options for their financial situation. This material contains the current opinions of the
        manager and such opinions are subject to change without notice. This material has been distributed for
        informational purposes only and should not be considered as investment advice or a recommendation of any
        particular security, strategy or investment product. Information contained herein has been obtained from sources
        believed to be reliable, but not guaranteed. No part of this material may be reproduced in any form, or referred
        to in any other publication, without express written permission. PIMCO is a trademark of Allianz Asset
        Management of America LLC in the United States and throughout the world. ©{environment.year}, PIMCO
      </p>
      <p>
        The Index is the property of Pacific Investment Management Company LLC ("PIMCO"). The Index will be calculated
        independently from PIMCO. It is not possible to invest directly in an unmanaged index.
      </p>
      <p>
        The information provided herein is not directed at any investor or category of investors and is provided solely
        as general information about our products and services and to otherwise provide general investment education. No
        information contained herein should be regarded as a suggestion to engage in or refrain from any
        investment-related course of action as none of PIMCO nor any of its affiliates is undertaking to provide
        investment advice, act as an adviser to any plan or entity subject to the Employee Retirement Income Security
        Act of 1974, as amended, individual retirement account or individual retirement annuity, or give advice in a
        fiduciary capacity with respect to the materials presented herein. If you are an individual retirement investor,
        contact your financial advisor or other fiduciary unrelated to PIMCO about whether any given investment idea,
        strategy, product or service described herein may be appropriate for your circumstances.
      </p>
      <PrivacyPolicyComponent />
      <div className="allianz-line"><span>A company of <img src={logo} alt={"Allianz Logo"} className="allianz-logo" width={"70"} height={"19"}/></span></div>
    </div>
  );
}
