import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Loader } from '../../loader/loader.component'

import './stack-chart.component.scss'
type stckChartType = {
  data: object[]
  category: () => void
  asOfDate: string
  isLoading: boolean,
  setMax:boolean
}

export default function StackChart({
  data,
  category,
  asOfDate,
  isLoading,
  setMax
}: stckChartType) {
  if (data.length === 0 || isLoading) {
    return <Loader />
  }
  ;(Highcharts as any).seriesTypes.line.prototype.drawLegendSymbol = (
    Highcharts as any
  ).seriesTypes.area.prototype.drawLegendSymbol
  const options = {
    chart: {
      type: 'column',
      height: '400',
      reflow: true,
    },
    container: {
      spacingBottom: 40,
    },

    lang: {
      noData: 'Unable to load data for this section',
    },
    noData: {
      style: {
        width: 500,
        fontSize: '18px',
        color: '#D95443',
      },
    } as Highcharts.NoDataOptions,
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> {point.change}%<br/>',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      changeDecimals: 2,
      split: false,
      shared:true,
      xDateFormat: '%m/%d/%Y',
      shape: "square"
    },
    legend: {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'top',
      symbolHeight: 12,
      symbolWidth: 12,
      symbolRadius: 2,
      width: '90%',
      fontSize: 14,
      itemStyle: {
        color: '#272B34',
        fontFamily: 'Roboto Condensed',
        fontSize: 14,
      },
      itemMarginBottom: 10,
      style: {
        'stroke-line-cap': 'square',
        symbolRadius: 0,
      },
    },
    rangeSelector: {
      allButtonsEnabled: true,
      selected: 4,
    },
    xAxis: {
      crosshair: true,
      type: 'datetime',
      labels: {
        rotation: -45,
        useHTML: false,
        style: {
          fontSize: '15px',
        },
      },
      dateTimeLabelFormats: {
        day: '%b %e %Y',
        week: '%b %e %Y',
        month: '%b %Y',
        year: '%Y',
      },
      tickInterval: category(),
    },
    yAxis: {
      title: {
        text: '( Weight % )',
      },
     max:setMax?100:null
    },
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [...data],
  }
  Highcharts.setOptions({
    colors: [
      '#286DFF',
      '#00BFB3',
      '#0013BF',
      '#F36700',
      '#FFBC11',
      '#0BA713',
      '#6B48FF',
      '#FF5343',
      '#0BA713',
      '#00B5E2',
      '#DC1895',
      '#6FB13A',
      '#008DB7',
      '#C58C00',
      '#9B0675',
    ],
    time: {
      useUTC: false,
    },
  })

  return (
    <div  className='chart-cntr'>
      <div id='stack-chart' className='stack-chart-container'>
        {' '}
        {isLoading ? (
          <div className="chart-loader-compare ">
            <div className="loading-state-comp dots">
              <div className="lds-ellipsis"/>
              <div className="lds-ellipsis"/>
              <div className="lds-ellipsis"/>
              <div className="lds-ellipsis"/>
            </div>
          </div>
        ) : (       
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            allowChartUpdate={true}
            updateArgs={[true]}
          /> 
        )}
      </div>
    </div>
  )
}
