import jsPDF from "jspdf"
import 'src/assets/fonts/RobotoCondensed-Bold-bold.js';

import 'src/assets/fonts/RobotoCondensed-Regular-normal.js';
import 'src/assets/fonts/Senhan-Bold-bold.js';
import {environment} from 'src/environments/environment';
const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

class pdfExportService {

    download_pdf_file(dataUrl: any, asOfDate: string, selRangeBtn: string, chartSeriesName: string, sourceName: string, isAssetByMonth: boolean, showBacktestDisclosure: boolean, isLageDataSet: boolean) {
        let today = new Date()
        let formatTime = today.toLocaleTimeString(undefined, {
            hour12: true,
            hour: '2-digit',
            minute: '2-digit',
        })
        const pdf = new jsPDF({
            orientation: 'p',
            putOnlyUsedFonts: true,
            format: [216, 279.4],
        })
        pdf.setTextColor('#272B34')
        pdf.setFont('Senhan-Bold', 'bold')
        pdf.setFontSize(13)
        pdf.text('PIMCO PRO', 14, isLageDataSet ? 10 : 13)
        pdf.setDrawColor('#BABEC7')
        pdf.setLineWidth(0.28)
        pdf.line(14, isLageDataSet ? 14 : 19, 202, isLageDataSet ? 14 : 19)
        pdf.setFont('Senhan-Bold', 'bold')
        pdf.setFontSize(24)
        pdf.text('PIMCO Index Data', 14, isLageDataSet ? 25 : 36)
        pdf.setFontSize(24)
        pdf.setFont('RobotoCondensed-Bold', 'bold')
        pdf.setFontSize(12)
        pdf.text(sourceName, 14, isLageDataSet ? 32 : 49)
        pdf.setFontSize(8)
        pdf.setFont('RobotoCondensed-Regular', 'normal')
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = 192;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        if (isAssetByMonth) {
            pdf.text(`${months[parseInt(asOfDate.split('/')[0]) - 1]} ${asOfDate.split('/')[2]}`, 14, isLageDataSet ? 37 : 57)
            pdf.addImage(dataUrl, 'JPEG', 14, isLageDataSet ? 41 : 62, 190, isLageDataSet ? pdfHeight - 12 : pdfHeight)
        } else {
            pdf.text(
                `(${selRangeBtn}) - (${asOfDate})`,
                14,
                57,
            ); pdf.addImage(dataUrl, 'JPEG', 12, 64, 192, pdfHeight)
        }
        pdf.setFont('RobotoCondensed-Regular', 'normal')
        pdf.setFontSize(8)
        pdf.setTextColor('#272B34')
        if (showBacktestDisclosure) {
            pdf.text('HYPOTHETICAL PERFORMANCE RESULTS HAVE MANY INHERENT LIMITATIONS, SOME OF WHICH ARE DESCRIBED BELOW. NO REPRESENTATION IS BEING MADE THAT ANY ACCOUNT WILL OR IS LIKELY TO ACHIEVE PROFITS OR LOSSES SIMILAR TO THOSE SHOWN. IN FACT, THERE ARE FREQUENTLY SHARP DIFFERENCES BETWEEN HYPOTHETICAL PERFORMANCE RESULTS AND THE ACTUAL RESULTS SUBSEQUENTLY ACHIEVED BY ANY PARTICULAR TRADING PROGRAM.', 14, 208, { maxWidth: 184 })
            pdf.text('ONE OF THE LIMITATIONS OF HYPOTHETICAL PERFORMANCE RESULTS IS THAT THEY ARE GENERALLY PREPARED WITH THE BENEFIT OF HINDSIGHT. IN ADDITION, HYPOTHETICAL TRADING DOES NOT INVOLVE FINANCIAL RISK, AND NO HYPOTHETICAL TRADING RECORD CAN COMPLETELY ACCOUNT FOR THE IMPACT OF FINANCIAL RISK IN ACTUAL TRADING. FOR EXAMPLE, THE ABILITY TO WITHSTAND LOSSES OR TO ADHERE TO A PARTICULAR TRADING PROGRAM IN SPITE OF TRADING LOSSES ARE MATERIAL POINTS WHICH CAN ALSO ADVERSELY AFFECT ACTUAL TRADING RESULTS. THERE ARE NUMEROUS OTHER FACTORS RELATED TO THE MARKETS IN GENERAL OR TO THE IMPLEMENTATION OF ANY SPECIFIC TRADING PROGRAM WHICH CANNOT BE FULLY ACCOUNTED FOR IN THE PREPARATION OF HYPOTHETICAL PERFORMANCE RESULTS AND ALL OF WHICH CAN ADVERSELY AFFECT ACTUAL TRADING RESULTS', 14, 221, { maxWidth: 184 })
        }



        pdf.setDrawColor('#BABEC7')
        pdf.line(14, 241, 202, 241)
        pdf.setFont('RobotoCondensed-Regular', 'normal')
        pdf.setFontSize(8)
        pdf.setTextColor('#272B34')
        pdf.text(
            `Data is provided for illustrative purposes and is not indicative of the past or future performance of any PIMCO product. The Index is the property of Pacific Investment Management Company LLC ("PIMCO"). The Index will be calculated independently from PIMCO. It is not possible to invest directly in an unmanaged index. All investments contain risk and may lose value. Investors should consult their investment professional prior to making an investment decision. PIMCO is a trademark of Allianz Asset Management of America LLC in the United States and throughout the world. Pacific Investment Management Company LLC, 650 Newport Center Drive, Newport Beach, CA 92660, 800-387-4626. ©${environment.year} PIMCO. CMR2023-0117-2428415`,
            14,
            246.5,
            { maxWidth: 184 },
            40,
        )
        pdf.setFont('RobotoCondensed-Bold', 'bold')
        pdf.text('PDF Exported on:', 14, 266.5)
        pdf.setFont('RobotoCondensed-Regular', 'normal')
        pdf.text(
            `${months[today.getUTCMonth()]
            } ${today.getDate()}, ${today.getFullYear()} - ${formatTime}`,
            34,
            266.5,
        )
        pdf.text(`Page 1 of ${pdf.getNumberOfPages()}`, 189, 266.5)
        pdf.save(
            `${chartSeriesName.split(' ').join('_')}_${today.toLocaleDateString(
                undefined,
                { month: '2-digit' },
            )}.${today.getDate()}.${today.getFullYear()}.pdf`,
        )
    }
}

const _pdfExportService = new pdfExportService()

export default _pdfExportService
