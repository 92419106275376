import { Link } from "react-router-dom";
import "./not-found.component.scss";

export function NotFoundPage() {
  return (
    <div className="not-found-page-container">
      <div data-testid="not-found-page" className="container">
        <div className="detail-item">
          <p>We have encountered an unexpected error</p>
          <p>Please return to your dashboard and try again</p>
          <div className="btn-dashboard">
            <Link to="/">
              <span className="pim-btn">
                <button className="btn-primary">Dashboard</button>
              </span>
            </Link>
          </div>
          <p>If you continue to see this page</p>
          <p>
            <a href="mailto:pro@pimco.com">pro@pimco.com</a>
          </p>
        </div>
      </div>
    </div>
  );
}
