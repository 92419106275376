import { Modal } from '@mui/material'
import React from 'react'
import closeIcon from 'src/assets/images/closeIcon.svg'
import './contact-us-modal.component.scss'
import mailIcon from '../../../../assets/images/contactIcon.svg'
import webIcon from '../../../../assets/images/webIcon.svg'

type ContactUsModalProps = {
  onClose: Function
}
export const ContactUsModalComponent: React.FunctionComponent<ContactUsModalProps> = ({
  onClose,
}) => {
  return (
    <div>
      <Modal
        onClose={() => {
          onClose()
        }}
        open={true}
      >
        <div className="contact-us-modal">
          <div className="t-c-modal ">
            <div className="modal-header">
              <h3>Contact Us</h3>
              <button
                className="close-modal"
                data-testid="contact-modal-test-id"
                onClick={() => {
                  onClose()
                }}
              >
                <img src={closeIcon} alt="close-icon" className="close-Icon" />
              </button>
            </div>
            <div className="contact-us-card-container">
              <div className="contact-us-cards">
                <div className="contact-card email-card">
                  <div className="card-logo">
                    <img src={mailIcon} alt="mail-icon" />
                  </div>
                  <div className="card-info">
                    <div className="info-header">
                      PIMCO Custom Indices Email
                    </div>
                    <div className="info-content">
                      <a
                        className="email-address"
                        href="mailto:customindex@pimco.com"
                      >
                        customindex@pimco.com
                      </a>
                    </div>
                  </div>
                </div>
                <div className="contact-card">
                  <div className="card-logo">
                    <img src={webIcon} alt="web-icon" />
                  </div>
                  <div className="card-info">
                    <div className="info-header">PIMCO Information Website</div>
                    <div className="info-content">
                      <a
                        className="email-address"
                        href="https://www.pimco.com/en-us/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        www.pimco.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
