import { getIndexDataKey } from "../models/indexData"

export const getId = () => {
    let loc = window.location
    var url = loc.pathname.replace('/', '').split('/')[0]
    const id = getIndexDataKey(url.toLowerCase())
    return id
}
export const isDate = (value: unknown): value is Date => {
    return value instanceof Date && !isNaN(+value);
}
