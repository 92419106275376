import html2canvas from 'html2canvas';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import pimindServices from 'src/app/services/pimind-services';
import {summarySelector} from 'src/app/slices/summary/summary.slice';
import {getId, isDate} from 'src/app/utils/utils';
import 'src/assets/fonts/RobotoCondensed-Bold-bold.js';

import 'src/assets/fonts/RobotoCondensed-Regular-normal.js';
import 'src/assets/fonts/Senhan-Bold-bold.js';
import {GroupedButton} from '../../shared/button-group/btn-group.component';

import LineChart from '../../shared/charts/line-chart/line-chart.component';
import {indexesHidesBackTestData} from '../../shared/const-value';
import {DropDown} from '../../shared/dropdown/drop-down.component';
import {Loader} from '../../shared/loader/loader.component';
import {IndexPerformanceComponent} from '../Index-performance/index-performance-component';
import _pdfExportService from "src/app/services/pdfExport-service";
import './historical-data.component.scss';
import { TabsDisclosureComponent } from '../tab-disclosure/tabs-disclosure.component';
import _excelExportService from 'src/app/services/excelExport-service';
import { useLocation } from 'react-router-dom';

type HistoricalDataProps = {};

type historicalDataType = {
  'Period EOD': number[] | []
  'Period EOM': number[] | []
}

type historicalDateType = {
  'Period EOD': Date[] | []
  'Period EOM': Date[] | []
}

export const HistoricalDataComponent: React.FunctionComponent<HistoricalDataProps> = () => {
  const { data } = useSelector(summarySelector);
  const today = useMemo(() => new Date(), []);
  const location = useLocation()
  const grpButton = ['1 Mo', '3 Mo', '1 Yr', '5 Yr', 'Max', 'Custom'];
  const [historyData, setHistoryData] = useState<(number | Date)[][] | []>([]);
  const [selRangeBtn, setSelRangeBtn] = useState<string>('5 Yr');
  const filterData = ['Period EOD', 'Period EOM'];
  const [filterPer, setFilterPer] = useState<string>('Period EOD');
  const [historicalDate, setHistoricalDate] = useState<historicalDateType>({
    'Period EOD': [],
    'Period EOM': []
  });
  const [historicalData, setHistoricalData] = useState<historicalDataType>({
    'Period EOD': [],
    'Period EOM': []
  });
  const [duration, setDuration] = useState(0);
  const [isLoading, setIsloading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [customStartDate, setCustomStartDate]: any = useState(null);
  const [customEndDate, setCustomEndDate]: any = useState(null);
  const [asOfDate, setAsofDate] = useState('');
  const [chartSeriesName, setSeriesName] = useState('');
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [startDate, setStartDate] = useState('')
  const [endDate,setEndDate]=useState('')
  const [isMaxCall, setIsMaxCall] = useState({
    'Period EOD': false,
    'Period EOM': false
  });
  const exportList = ['Export', 'PDF', 'Excel'];
  const [selectExportItem, setselectExportItem] = useState('Export');
  
  useEffect(() => {
    setIsError(false)
    const today = new Date();
    const startDate = new Date(
      today.getFullYear() - 5,
      today.getMonth(),
      today.getDate()
    );
    setStartDate(startDate.toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }))
    setIsloading(true);
    pimindServices
      .gethistoricalData(
        'EOD',
        startDate.toISOString().split('T')[0],
        today.toISOString().split('T')[0],
        false,
      )
      .then(
        res => {
          if (res.data.historicalData.length > 0) {
            setHistoricalData({
              'Period EOD': [...res.data.historicalData],
              'Period EOM': [],
            })
            setHistoricalDate({
              'Period EOD': res.data.historicalDates,
              'Period EOM': [],
            })
            setIsloading(false)
            setIsError(false)
          } else {
            setIsloading(false)
            setIsError(true)
          }
        },
        () => {
          setIsError(true)
        },
      )
  }, [location]);

  useEffect(() => {
    if (data?.asOfDate?.asOfDate !== undefined) {
      setSeriesName(data.data.sourceName);
      const date = data?.asOfDate?.asOfDate.split('T')[0].split('-');
      const formatDte = `${date[1]}/${date[2]}/${date[0]}`;
      setAsofDate(formatDte);
    }
  }, [data,location]);

  const updateDuration = useCallback(async () => {
    setIsError(false)
    const filterChartData = async (
      historicalData: Date[],
      historicalDate: number[],
      type: string
    ) => {
      if (type === 'max') {
        const combineDate = [];
        for (let i = 0; i < historicalData.length; i++) {
          const entry = new Date(historicalData[i]);
          combineDate.push([entry.getTime(), historicalDate[i]]);
        }
        await combineDate.sort((a, b) => a[0] - b[0])
        combineDate.length > 0 && setStartDate(new Date(combineDate[0][0]).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }))
        combineDate.length > 0 && setEndDate(new Date(combineDate[combineDate.length-1][0]).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }))
        setHistoryData([...combineDate]);
        setIsloading(false);
      } else if (type === 'custom') {
        const combineDate = [];
        const priorDate = new Date(customStartDate);
        const preDate = new Date(customEndDate);
        for (let i = 0; i < historicalData.length; i++) {
          const entry = new Date(historicalData[i]);
          if (
            entry.getTime() >= priorDate.getTime() &&
            entry.getTime() <= preDate.getTime()
          ) {
            combineDate.push([entry.getTime(), historicalDate[i]]);
          }
        }
        await combineDate.sort((a, b) => a[0] - b[0])
        combineDate.length > 0 && setStartDate(new Date(combineDate[0][0]).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }))
        combineDate.length > 0 && setEndDate(new Date(combineDate[combineDate.length-1][0]).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }))
        setHistoryData([...combineDate]);
        setIsloading(false);
      } else {
        const numberOfDays = setTimeDuration(selRangeBtn);
        let priorDate = new Date(
          Date.now() - numberOfDays * 24 * 60 * 60 * 1000
        );
        const combineDate = [];
        for (let i = 0; i < historicalData.length; i++) {
          const entry = new Date(historicalData[i]);

          if (entry.getTime() >= priorDate.getTime()) {
            combineDate.push([entry.getTime(), historicalDate[i]]);
          }
        }
        await combineDate.sort((a, b) => a[0] - b[0])
        combineDate.length > 0 && setStartDate(new Date(combineDate[0][0]).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }))
        combineDate.length > 0 && setEndDate(new Date(combineDate[combineDate.length - 1][0]).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }))
        setHistoryData([...combineDate]);
        setIsloading(false);
      }
    };
    const getCustomDateData = async () => {
      const today = new Date();
      setIsError(false)
      const startDate = new Date(
        today.getFullYear() - 5,
        today.getMonth(),
        today.getDate()
      );
      if (startDate < customStartDate) {
        if (filterPer === 'Period EOD') {
          const formattedDateList = historicalDate['Period EOD'];
          const customhistoricalData = historicalData['Period EOD'];
          filterChartData(formattedDateList, customhistoricalData, 'custom');
        } else {
          setIsloading(true);
          const startDate = customStartDate.$d.toLocaleDateString().split('/');
          const endDate = customEndDate.$d.toLocaleDateString().split('/');
          pimindServices
            .gethistoricalData(
              'EOM',
              `${startDate[2]}-${
                startDate[0] < 10 ? '0' + startDate[0] : startDate[0]
              }-${startDate[1] < 10 ? '0' + startDate[1] : startDate[1]}`,
              `${endDate[2]}-${endDate[0] < 10 ? '0' + endDate[0] : endDate[0]}-${
                endDate[1] < 10 ? '0' + endDate[1] : endDate[1]
              }`,
              true
            )
            .then(
              async res => {
                if (res.data.historicalData.length > 0) {
                const formattedDateList = res.data.historicalDates;
                const customhistoricalData = [...res.data.historicalData];
                filterChartData(
                  formattedDateList,
                  customhistoricalData,
                  'custom'
                );
                }else{
                setIsloading(false);
                setIsError(true);
                }
              },
              () => {
                setIsloading(false);
                setIsError(true);
              }
            );
        }
      } else if (isMaxCall['Period EOD'] && filterPer === 'Period EOD') {
        const formattedDateList = historicalDate['Period EOD'];
        const customhistoricalData = historicalData['Period EOD'];
        filterChartData(formattedDateList, customhistoricalData, 'custom');
      } else if (isMaxCall['Period EOM'] && filterPer === 'Period EOM') {
        setIsloading(true);
        const startDate = customStartDate.$d.toLocaleDateString().split('/');
        const endDate = customEndDate.$d.toLocaleDateString().split('/');
        pimindServices
          .gethistoricalData(
            'EOM',
            `${startDate[2]}-${
              startDate[0] < 10 ? '0' + startDate[0] : startDate[0]
            }-${startDate[1] < 10 ? '0' + startDate[1] : startDate[1]}`,
            `${endDate[2]}-${endDate[0] < 10 ? '0' + endDate[0] : endDate[0]}-${
              endDate[1] < 10 ? '0' + endDate[1] : endDate[1]
            }`,
            true
          )
          .then(
            async res => {
              if(res.data.historicalData.length > 0){
              const formattedDateList = res.data.historicalDates;
              const customhistoricalData = [...res.data.historicalData];
              filterChartData(formattedDateList, customhistoricalData, 'custom');
              }else{
                setIsloading(false);
                setIsError(true);
              }
            },
            () => {
              setIsloading(false);
              setIsError(true);
            }
          );
      } else {
        setIsloading(true);
        const startDate = customStartDate.$d.toLocaleDateString().split('/');
        const endDate = customEndDate.$d.toLocaleDateString().split('/');
        pimindServices
          .gethistoricalData(
            filterPer.slice(7),
            `${startDate[2]}-${
              startDate[0] < 10 ? '0' + startDate[0] : startDate[0]
            }-${startDate[1] < 10 ? '0' + startDate[1] : startDate[1]}`,
            `${endDate[2]}-${endDate[0] < 10 ? '0' + endDate[0] : endDate[0]}-${
              endDate[1] < 10 ? '0' + endDate[1] : endDate[1]
            }`,
            true
          )
          .then(
            async res => {
              if(res.data.historicalData.length > 0){
              const formattedDateList = res.data.historicalDates;
              const customhistoricalData = [...res.data.historicalData];
              filterChartData(formattedDateList, customhistoricalData, 'custom');
              }else{
                setIsloading(false);
                setIsError(true);
              }
            },
            () => {
              setIsloading(false);
              setIsError(true);
            }
          );
      }
    };

    const getMaxDurationData = async () => {
      setCustomEndDate(null);
      setCustomStartDate(null);
      if (filterPer === 'Period EOD' && isMaxCall['Period EOD']) {
        const formattedDateList = historicalDate['Period EOD'];
        const customhistoricalData = historicalData['Period EOD'];
        filterChartData(formattedDateList, customhistoricalData, 'max');
      } else if (filterPer === 'Period EOM' && isMaxCall['Period EOM']) {
        const formattedDateList = historicalDate['Period EOM'];
        const customhistoricalData = historicalData['Period EOM'];
        filterChartData(formattedDateList, customhistoricalData, 'max');
      } else {
        const maxStartHistoryDate = (indexesHidesBackTestData().includes(data.data.sourceCode)) ? new Date(data?.data?.launchDate) : new Date(1999,11,1)
        const maxStartDate = maxStartHistoryDate.toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/');
        pimindServices
          .gethistoricalData(
            filterPer.slice(7),
            maxStartDate[2] + "-" + maxStartDate[0] + "-" + maxStartDate[1],
            today.toISOString().split('T')[0],
            false
          )
          .then(
            async res => {
              if (filterPer === 'Period EOD') {
                setHistoricalData({
                  'Period EOD': res.data.historicalData,
                  'Period EOM': historicalData['Period EOM']
                });
                setHistoricalDate({
                  'Period EOD': res.data.historicalDates,
                  'Period EOM': historicalDate['Period EOM']
                });
                setIsMaxCall({
                  'Period EOD': true,
                  'Period EOM': isMaxCall['Period EOM']
                });
              } else {
                setHistoricalData({
                  'Period EOM': res.data.historicalData,
                  'Period EOD': historicalData['Period EOD']
                });
                setHistoricalDate({
                  'Period EOM': res.data.historicalDates,
                  'Period EOD': historicalDate['Period EOD']
                });
                setIsMaxCall({
                  'Period EOD': isMaxCall['Period EOD'],
                  'Period EOM': true
                });
              }
              const formattedDateList = res.data.historicalDates;
              const customhistoricalData = [...res.data.historicalData];
              filterChartData(formattedDateList, customhistoricalData, 'max');
            },
            () => {
              setIsloading(false);
              setIsError(true);
            }
          );
      }
    };

    const setTimeDuration = (duration: string) => {
      let startDate: Date = new Date();
      let endDate = new Date();
      let durationValue: number;

      if (duration === '1 Mo') {
        startDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth() - 1,
          endDate.getDate()
        );
      } else if (duration === '3 Mo') {
        startDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth() - 3,
          endDate.getDate()
        );
      } else if (duration === '1 Yr') {
        startDate = new Date(
          endDate.getFullYear() - 1,
          endDate.getMonth(),
          endDate.getDate()
        );
      } else if (duration === '5 Yr') {
        startDate = new Date(
          endDate.getFullYear() - 5,
          endDate.getMonth(),
          endDate.getDate()
        );
      }

      durationValue = Math.ceil(
        (endDate.getTime() -
          startDate.getTime() +
          endDate.getTimezoneOffset() * 60000) /
        (24 * 3600 * 1000)
      );
      return durationValue;
    };

    const getSelectedDurationData = async () => {
      setCustomEndDate(null);
      setCustomStartDate(null);
      if (filterPer === 'Period EOD') {
        const formattedDateList = historicalDate['Period EOD'];
        const dataList = historicalData['Period EOD'];
        filterChartData(formattedDateList, dataList, 'select');
      } else {
        if (historicalDate['Period EOM'].length > 0) {
          const formattedDateList = historicalDate['Period EOM'];
          const dataList = historicalData['Period EOM'];
          filterChartData(formattedDateList, dataList, 'select');
        } else {
          const today = new Date();
          const startDate = new Date(
            today.getFullYear() - 5,
            today.getMonth(),
            today.getDate()
          );
          pimindServices
            .gethistoricalData(
              'EOM',
              startDate.toISOString().split('T')[0],
              today.toISOString().split('T')[0],
              false
            )
            .then(
              res => {
                setHistoricalData({
                  'Period EOM': [...res.data.historicalData],
                  'Period EOD': historicalData['Period EOD']
                });
                setHistoricalDate({
                  'Period EOM': res.data.historicalDates,
                  'Period EOD': historicalDate['Period EOD']
                });
                setIsloading(false);
                filterChartData(
                  res.data.historicalDates,
                  res.data.historicalData,
                  'select'
                );
              },
              () => {
                setIsError(true);
              }
            );
        }
      }
    };
    if (selRangeBtn === 'Custom') {
       if (customStartDate && customEndDate && isDate(customStartDate.$d) && isDate(customEndDate.$d)) {
        await getCustomDateData();
      }
    } else if (selRangeBtn === 'Max') {
      setIsloading(true);
      setTimeDuration(selRangeBtn);
      await getMaxDurationData();
    } else {
      setIsloading(true);
      setTimeDuration(selRangeBtn);
      await getSelectedDurationData();
    }
  }, [
    isMaxCall,
    selRangeBtn,
    customStartDate,
    customEndDate,
    historicalData,
    historicalDate,
    today,
    filterPer,
    data
  ]);

  useEffect(() => {
    updateDuration();
  }, [updateDuration,location.pathname]);

  const getSelectedDurationTickInterval = useCallback((): number => {
    let tickInterval: number = 0;
    if (selRangeBtn === '1 Mo') {
      tickInterval = 86400000;
    } else if (selRangeBtn === '3 Mo') {
      tickInterval = 604800000;
    } else if (selRangeBtn === '1 Yr') {
      tickInterval = 2628000000;
    } else if (selRangeBtn === '5 Yr') {
      tickInterval = 7884000000;
    } else if (selRangeBtn === 'Max') {
      tickInterval = 31556952000;
    } else if (selRangeBtn === 'Custom') {
      if (customStartDate && customEndDate) {
        const differenceInTime =
          customEndDate?.$d.getTime() - customStartDate?.$d.getTime();
        const noOfDays = differenceInTime / (1000 * 3600 * 24);
        if (noOfDays <= 31) {
          tickInterval = 86400000;
        } else if (noOfDays > 31 && noOfDays <= 180) {
          tickInterval = 604800000;
        } else if (noOfDays > 180 && noOfDays <= 365) {
          tickInterval = 2628000000;
        } else if (noOfDays > 365 && noOfDays <= 3650) {
          tickInterval = 7884000000;
        } else {
          tickInterval = 31556952000;
        }
      } else {
        return duration;
      }
    }
    return tickInterval;
  }, [customEndDate, customStartDate, duration, selRangeBtn]);
  useEffect(() => {
    if (selRangeBtn !== 'Custom') {
      const tickInterval = getSelectedDurationTickInterval();
      setDuration(tickInterval);
    }
   }, [selRangeBtn, getSelectedDurationTickInterval])

  const getPDF = useCallback(async() => {
    const documentElement = document.getElementById("line-chart")
      if (documentElement !== null) {
        html2canvas(documentElement, {scale: 5}).then(
          async (result: {toDataURL: (id: string) => any}) => {
            const dataURL = result.toDataURL('image/jpeg')
            setIsPdfLoading(true)
            await _pdfExportService.download_pdf_file(dataURL, endDate, startDate, chartSeriesName, data.data.sourceName,false,new Date(startDate).getTime() < new Date(data?.data?.launchDate).getTime(),false)
            setIsPdfLoading(false)
          setselectExportItem('Export')
          },
        )
      }
  }, [ data, chartSeriesName,startDate,endDate])

  function formatDate(timeStamp: number) {
    let date = new Date(Math.round(Number(timeStamp)));
    let day = date.getDate();
    let month = date.getMonth() + 1;
    return month + '/' + day + '/' + date.getFullYear();
  }

  const getCsv = useCallback(() => {
    const headers = ['As of Date', 'Description', 'Index Level'];
    const excelData = historyData.map((res: any) => {
      let dataSet: any = [
        formatDate(res[0]),
        chartSeriesName,
        (Math.round(res[1] * 100) / 100).toString()
      ];
      return dataSet;
    });
    _excelExportService.download_excel_file(excelData, headers, chartSeriesName,new Date(startDate).getTime() < new Date(data?.data?.launchDate).getTime());
    setselectExportItem('Export');
  }, [chartSeriesName, historyData,data,startDate]);

  useEffect(() => {
    if (selectExportItem === 'PDF') {
      getPDF()
    }
    else if (selectExportItem === 'Excel' ) {
      getCsv()
    }

  }, [selectExportItem, getPDF, getCsv])

    return (
      <>
        <div className="btn-container">
          <DropDown
            dropDownList={filterData}
            updateSel={(dropdownItem: string) => {
              if (selRangeBtn === 'Custom' && customEndDate ===null && customEndDate===null) {
                setSelRangeBtn('5 Yr')
                setFilterPer(dropdownItem)
              } else {
                setFilterPer(dropdownItem)
              }
            }}
            selectedItem="Period EOD"
          />
          <div className="button-left">
            <GroupedButton
              buttonData={grpButton}
              selectedButton={selRangeBtn}
              updateSelectedItem={(dropdownItem: string) => {
                setSelRangeBtn(dropdownItem)
              }}
              customStartDate={customStartDate}
              customEndDate={customEndDate}
              updateStartDate={(date: Date) => {
                setCustomStartDate(date)
              }}
              updateEndDate={(date: Date) => {
                setCustomEndDate(date)
              }}
            />
            <div className='export-btn-cntr'>
            <DropDown
              isLoading={isPdfLoading}
              dropDownList={exportList}
              updateSel={(event: string) => {
                setselectExportItem(event)
              }}
              selectedItem={selectExportItem}
              />
              </div>
          </div>
        </div>
        {!isLoading && !isError && (
          <div  className='historical-chart-cntr'>
          <LineChart
            name={chartSeriesName}
            data={historyData}
            category={getSelectedDurationTickInterval}
            asOfDate={asOfDate}
            isLoading={isLoading}
            />
            </div>
        )}
        {isLoading && <Loader />}
        {!isLoading && isError && (
          <div className="error-text-section">
            <span>Data Unavailable</span>
          </div>
        )}
        <div className="horizontal-row" />
        <p className="sub-disclosure">
          Returns are cumulative for periods longer than one year.
          {getId() === 'EFI' && (
            <>Performance includes deduction of a 0.50% annual expense.</>
          )}
        </p>
        <IndexPerformanceComponent
          isCustomSelected={
            selRangeBtn === 'Custom' && customStartDate && customEndDate
          }
          customStartDate={customStartDate}
          customEndDate={customEndDate}
        />
         {data?.data?.launchDate  &&
        <TabsDisclosureComponent showBackTestDisclosure={new Date(startDate).getTime() < new Date(data?.data?.launchDate).getTime()} />}
      </>
    )
  };
