import './btn-group.component.scss'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import * as uuid from 'uuid'
import {DesktopDatePicker} from '@mui/x-date-pickers'
import {useEffect} from 'react'
import dayjs from 'dayjs'
import { indexesHidesBackTestData } from '../const-value'
import { useSelector } from 'react-redux'
import { summarySelector } from 'src/app/slices/summary/summary.slice'

type ButtonProps = {
  buttonData: string[]
  selectedButton: String
  updateSelectedItem: Function
  customStartDate: any
  customEndDate: any
  updateStartDate: Function
  updateEndDate: Function
}

export const GroupedButton: React.FunctionComponent<ButtonProps> = ({
  buttonData,
  selectedButton,
  updateSelectedItem,
  customStartDate,
  customEndDate,
  updateStartDate,
  updateEndDate,
}) => {
  const { data } = useSelector(summarySelector)
  useEffect(() => {
    const startDateNodes = document.querySelectorAll(
      '.custom-start-date .MuiInputBase-input',
    )
    const endDateNodes = document.querySelectorAll(
      '.custom-end-date .MuiInputBase-input',
    )
    startDateNodes.forEach((node: Element) => {
      node.setAttribute('aria-label', 'Start Date')
    })
    endDateNodes.forEach((node: Element) => {
      node.setAttribute('aria-label', 'End Date')
    })
  })
  const today=new Date()
  const startDate =
   new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()-1
    );
  return (
    <div className="shared-btn-container">
      <div className="button-bar">
        {buttonData.map((buttonElement: string, index: number) => (
          <button
            key={uuid.v4()}
            className={buttonElement === selectedButton ? 'btn-on' : ''}
            onClick={() => {
              updateSelectedItem(buttonElement)
            }}
            data-testid={`group-button-${index}`}
          >
            {buttonElement}
          </button>
        ))}
      </div>
      {selectedButton === 'Custom' && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="calender-input">
            {customEndDate ? (
              <DesktopDatePicker
                className="custom-start-date"
                value={customStartDate}
                onChange={newValue => {
                  if (indexesHidesBackTestData().includes(data.data.sourceCode) && newValue < new Date(data?.data?.launchDate)&& newValue < customEndDate && newValue<=new Date()) { updateStartDate(dayjs(new Date(data?.data?.launchDate))) }
                  else if (newValue > customEndDate && customEndDate<=new Date()) {
                    updateStartDate(dayjs(new Date(customEndDate.$d)))
                  } else if (newValue>new Date()) {
                    updateStartDate(dayjs(new Date()))
                  }
                  else {
                    updateStartDate(newValue)
                  }
                }}
                minDate={indexesHidesBackTestData().includes(data.data.sourceCode) ?dayjs(new Date(data?.data?.launchDate)):dayjs(new Date(1990,0,1))}
                maxDate={customEndDate}
              />
            ) : (
              <DesktopDatePicker
                className="custom-start-date"
                value={customStartDate}
                  onChange={newValue => {
                    if (indexesHidesBackTestData().includes(data.data.sourceCode) && newValue < new Date(data?.data?.launchDate) && newValue<new Date()) {
                      updateStartDate(dayjs(new Date(data?.data?.launchDate)))
                    }
                    else if (newValue>new Date()) {
                    updateStartDate(dayjs(new Date()))
                  }
                    else {
                      updateStartDate(newValue)
                    }
                  }}
                  maxDate={customEndDate?customEndDate:dayjs(startDate)}
                  minDate={indexesHidesBackTestData().includes(data.data.sourceCode) ? dayjs(new Date(data?.data?.launchDate)) : dayjs(new Date(1990, 0, 1))}
              />
            )}
            {customStartDate ? (
              <DesktopDatePicker
                className="custom-end-date"
                value={customEndDate}
                onChange={newValue => {
                  if (indexesHidesBackTestData().includes(data.data.sourceCode) && newValue < new Date(data?.data?.launchDate) && newValue > customStartDate && newValue<new Date()) {
                    updateEndDate(dayjs(new Date(data?.data?.launchDate)))
                  } else if (newValue < customStartDate && newValue < new Date()) {
                    updateEndDate(dayjs(new Date(customStartDate.$d)))
                  }
                  else if (newValue > new Date()) {
                    updateEndDate(dayjs(new Date()))
                  }
                  else {
                    updateEndDate(newValue)
                  }
                }}
                minDate={customStartDate}
                maxDate={dayjs(today)}
              />
            ) : (
              <DesktopDatePicker
                className="custom-end-date"
                value={customEndDate}
                  onChange={newValue => {
                  if (indexesHidesBackTestData().includes(data.data.sourceCode) && newValue < new Date(data?.data?.launchDate) && newValue<=new Date()) {
                    updateEndDate(dayjs(new Date(data?.data?.launchDate)))
                  }else if (newValue>new Date()) {
                    updateEndDate(dayjs(new Date()))
                  } else {
                    updateEndDate(newValue)
                  }
                  }}
                  maxDate={dayjs(today)}             
                  minDate={customStartDate?dayjs(customStartDate.$d):indexesHidesBackTestData().includes(data.data.sourceCode) ? dayjs(new Date(data?.data?.launchDate)):dayjs(new Date(1990,0,1))}
              />
            )}
          </div>
        </LocalizationProvider>
      )}
    </div>
  )
}
