import { Action,configureStore,PreloadedState,ThunkAction } from "@reduxjs/toolkit";
import {useDispatch} from 'react-redux';
import rootReducer,{RootState} from "../../app/slices";

const store=configureStore({reducer:rootReducer});

export function setupStore(preloadedState?:PreloadedState<RootState>){
    return configureStore({
        reducer:rootReducer,
        preloadedState
    })
}

export type AppDispatch = typeof store.dispatch;

export type AppStore=ReturnType<typeof setupStore>;

export type AppThunk = ThunkAction<void,RootState,null,Action<string>>;

export const useAppDispatch=()=>useDispatch<AppDispatch>();

export default  store;