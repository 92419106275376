import React from 'react'
import './loader.component.scss'

type LoaderProps = {}
export const Loader: React.FunctionComponent<LoaderProps> = () => {
  return (
    <div className="loader-holder" data-testid='loader'>
      <div className="dots">
        <div/>
        <div/>
        <div/>
        <div/>
      </div>
    </div>
  )
}
