import {RouterProvider} from 'react-router-dom'

import {router} from './app.routing'
import './app.scss'

export const App: React.FunctionComponent = () => {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  )
}
